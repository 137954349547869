// setABTestConfig.js
'use strict';
import { MERGE_ME_DATA, MERGE_OPERATION_DATA } from '../ActionTypes.js';

import * as MessageTypes from '../serviceWorker/AppMessageTypes.js';
import { sendMessageToSW } from '../serviceWorker/helpers.js';
import env from '../resource/env.js';
import getCurrentUnixTimestamp from '../resource/getCurrentUnixTimestamp.js';
import { setHeader, deleteHeader } from '../resource/fetchOptionHeader.js';

import getOperationData from '../selector/getOperationData.js';
import getMeData from '../selector/getMeData.js';
import getTimestampOffset, {
  Accuracy,
} from '../selector/getTimestampOffset.js';

import updateConfigurations from '../action/updateConfigurations.js';

const NO_AB_TOKEN_REFRESH_TIMEOUT = env.NO_AB_TOKEN_REFRESH_TIMEOUT || 6000;
const REFETCH_LEADING_SECONDS = 30; // TODO: remote config

let refetchTimeout = null;

/**
 * Set a b test config
 * @kind action
 * @param {Object} params
 * @param {string} params.abToken - the A/B test token.
 * @param {string} params.priority - ab token priority string.
 * @return {Promise} Action promise.
 */
const setABTestConfig =
  ({ abToken, priority }) =>
  async (dispatch, getState) => {
    const timestampOffsetSeconds =
      getTimestampOffset(getState(), Accuracy.SECOND) || 0;
    const now = getCurrentUnixTimestamp({
      offsetSeconds: timestampOffsetSeconds,
    });
    let exp;
    let experiments;

    if (abToken) {
      dispatch({
        type: MERGE_OPERATION_DATA,
        payload: {
          selectPath: ['config'],
          data: {
            abTestConfigMerged: {
              [priority]: false,
            },
          },
        },
      });
      const decode = (await import('jwt-decode')).jwtDecode;
      const decoded = decode(abToken);

      exp = decoded.exp;
      experiments = decoded.experiments || {};
    } else {
      dispatch({
        type: MERGE_OPERATION_DATA,
        payload: {
          selectPath: ['config'],
          data: {
            abTestConfigMerged: {
              [priority]: true,
            },
          },
        },
      });
    }

    clearTimeout(refetchTimeout);

    if (!abToken || (abToken && now > exp)) {
      deleteHeader({ key: 'X-AB' });

      if (abToken && now > exp) {
        const delay = +NO_AB_TOKEN_REFRESH_TIMEOUT;

        // at least 5 seconds
        if (!Number.isNaN(delay) && delay > 5000) {
          refetchTimeout = setTimeout(() => {
            const state = getState();
            const userId = getMeData(state, 'id');
            const clientId = getMeData(state, 'clientId');

            const event = {
              type: MessageTypes.GET_AB_TEST_TOKEN,
              payload: userId ? { userId } : { clientId },
            };

            sendMessageToSW(event);
          }, delay);
        }
      }

      return dispatch({
        type: MERGE_ME_DATA,
        payload: {
          ab: {
            [priority]: {
              exp: undefined,
              // if no token should override all experiments
              experiments: null,
              abToken: undefined,
            },
          },
        },
      });
    }

    setHeader({ key: 'X-AB', value: abToken });

    // refetch before exp - 10s
    const delaySeconds = exp - now - REFETCH_LEADING_SECONDS;

    refetchTimeout = setTimeout(() => {
      const state = getState();
      const userId = getMeData(state, 'id');
      const clientId = getMeData(state, 'clientId');

      const event = {
        type: MessageTypes.GET_AB_TEST_TOKEN,
        payload: userId ? { userId } : { clientId },
      };
      sendMessageToSW(event);
    }, delaySeconds * 1000);

    dispatch({
      type: MERGE_ME_DATA,
      payload: {
        ab: {
          [priority]: {
            exp,
            experiments,
            abToken,
          },
        },
      },
    });
    const currentConfigKey = getOperationData(
      getState(),
      ['config'],
      'currentConfigKey'
    );
    await dispatch(updateConfigurations({ configKey: currentConfigKey }));
    return dispatch({
      type: MERGE_OPERATION_DATA,
      payload: {
        selectPath: ['config'],
        data: {
          abTestConfigMerged: {
            [priority]: true,
          },
        },
      },
    });
  };

export default setABTestConfig;
