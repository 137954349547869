// getMixpanelViewId.js
'use strict';
import { matchPath } from 'react-router-dom';

import { CHAT_ID as AUTO_CHAT_CHAT_ID } from '../resource/autoChatConstants.js';
import { Progress as JoinProgress } from '../resource/joinConstants.js';
import { LIVESTREAM_PATH } from '../resource/liveStreamConstants.js';
import {
  ProfileListType,
  ProfileListPublishStatus,
  ManageProfileType,
} from '../resource/profileConstants.js';

let meId;
let meUsername;

export const VIEW_PATH_MAP = {
  '/': 'home-explore',
  '/video': 'home-video',
  '/story': 'home-story',
  '/livestream': 'channel-livestream',
  '/shorts/:messageId': 'shorts-detail',
  [`/shorts/:messageId/${ProfileListPublishStatus.DRAFT}`]:
    'unpublished-shorts-detail',
  '/flix-categories/:category': 'flix-categories',
  '/story-categories/:category': 'story-categories',
  '/user-categories/:category': 'user-categories',
  '/user-feed-grid/:category': 'user-feed-grid',
  '/user-leaderboard-grid/:category': 'user-leaderboard-grid',
  '/user-following-grid/:category': 'user-feed-grid',
  '/leaderboard/:region/all/:range': 'leaderboard-all',
  '/leaderboard/:region/broadcast/:range': 'leaderboard-unlock',
  '/leaderboard/:region/chat/:range': 'leaderboard-chat',
  '/leaderboard/:region/gift/:range': 'leaderboard-gift',
  '/leaderboard/:region/livestream/:range': 'leaderboard-livestream',
  '/leaderboard/:region/new/:range': 'leaderboard-new',
  '/search/all': 'search-all',
  '/search/flix': 'search-flix',
  '/search/swagger/online': 'search-creator-online',
  '/search/swagger/new': 'search-creator-new',
  '/search/story': 'search-story',
  '/search/hashtag': 'search-hashtag',
  '/hashtag/:query': 'hashtag',
  '/hashtag/:query/flix': 'hashtag-flix',
  '/hashtag/:query/story': 'hashtag-story',
  '/chat/:chatroomId/message/:messageId': 'message-detail',
  '/message/:messageId': 'message-detail',
  '/story/:messageId': 'message-detail',
  '/story-preview/:messageId': 'message-detail',
  '/flix/:messageId': 'flix-detail',
  '/user/:userId': 'user-profile',
  '/user/:userId/home': 'user-profile-home',
  '/user/:userId/image': 'user-profile-image',
  '/user/:userId/story': 'user-profile-story',
  '/user/:userId/flix': 'user-profile-flix',
  '/user/:userId/livestreamSession': 'user-profile-livestream-session',
  '/user/:userId/short': 'user-profile-shorts',
  [`/user/:userId/image/${ProfileListPublishStatus.DRAFT}`]:
    'user-profile-image-unpublished',
  [`/user/:userId/story/${ProfileListPublishStatus.DRAFT}`]:
    'user-profile-story-unpublished',
  [`/user/:userId/flix/${ProfileListPublishStatus.DRAFT}`]:
    'user-profile-flix-unpublished',
  [`/user/:userId/livestreamSession/${ProfileListPublishStatus.DRAFT}`]:
    'user-profile-livestream-session-unpublished',
  [`/user/:userId/short/${ProfileListPublishStatus.DRAFT}`]:
    'user-profile-shorts-unpublished',
  [`/user/:userId/all/${ProfileListPublishStatus.DRAFT}`]:
    'user-profile-all-unpublished',
  [`/user/:userId/${LIVESTREAM_PATH}`]: 'livestream-detail',
  '/user/:userId/chat': 'chatroom-fake',
  '/u/:username': 'user-profile',
  '/u/:username/home': 'user-profile-home',
  '/u/:username/image': 'user-profile-image',
  '/u/:username/story': 'user-profile-story',
  '/u/:username/flix': 'user-profile-flix',
  '/u/:username/livestreamSession': 'user-profile-livestream-session',
  '/u/:username/short': 'user-profile-shorts',
  [`/u/:username/image/${ProfileListPublishStatus.DRAFT}`]:
    'user-profile-image-unpublished',
  [`/u/:username/story/${ProfileListPublishStatus.DRAFT}`]:
    'user-profile-story-unpublished',
  [`/u/:username/flix/${ProfileListPublishStatus.DRAFT}`]:
    'user-profile-flix-unpublished',
  [`/u/:username/livestreamSession/${ProfileListPublishStatus.DRAFT}`]:
    'user-profile-livestream-session-unpublished',
  [`/u/:username/short/${ProfileListPublishStatus.DRAFT}`]:
    'user-profile-shorts-unpublished',
  [`/u/:username/all/${ProfileListPublishStatus.DRAFT}`]:
    'user-profile-all-unpublished',
  [`/u/:username/${LIVESTREAM_PATH}`]: 'livestream-detail',
  '/u/:username/chat': 'chatroom-fake',
  '/messagePack/list': 'my-profile-archive-messagepack-collection',
  '/product/:productId': 'product-detail',
  '/archive/userId/:id/story': 'my-profile-archive-cp-story',
  '/archive/userId/:id/flix': 'my-profile-archive-cp-flix',
  '/archive/userId/:id/chat': 'my-profile-archive-cp-chatroom',
  '/me/edit': 'my-profile-edit-page',
  '/profile/following': 'my-profile-following-list',
  '/profile/follower': 'my-profile-follower-list',
  '/profile/subscribed': 'subscribed',
  '/profile/subscriber': 'subscriber',
  '/settings': 'settings',
  '/settings/notification': 'settings-notification',
  '/settings/more': 'settings-more',
  '/settings/more/debug': 'settings-debug',
  '/settings/more/drm': 'settings-drm',
  '/settings/more/about': 'settings-about',
  '/settings/more/delete-account': 'settings-delete-account',
  '/settings/vvip': 'settings-channel-vip',
  '/settings/auto-addvalue': 'settings-auto-addvalue',
  '/settings/2257': 'settings-2257',
  '/settings/journal': 'journal',
  '/settings/referral': 'settings-referral',
  '/settings/referral/result': 'referral-result',
  '/settings/redeem': 'settings-redeem',
  '/settings/bank-account': 'payment-method',
  '/chat': 'chatlist',
  '/chat/start': 'chat-add-chatroom',
  [`/chat/${AUTO_CHAT_CHAT_ID}`]: 'chatroom-chat-feed',
  '/chat/:id': 'chatroom',
  '/chat/:id/media': 'media-library',
  '/chat/:id/search': 'chatroom-search',
  '/chat/user/:userId': 'chatroom-fake',
  '/audio/:messageId': 'audio-detail',
  '/freezone/model': 'freezone-pro',
  '/freezone/amateur': 'freezone-amateur',
  '/freezone/:messageId': 'flix-detail-freepeek',
  '/404': 'not-found',
  '/error': 'went-wrong',
  '/events/lottery': 'lottery-event-list',
  '/events/assurance': 'dating-assurance-event-list',
  [`/join/${JoinProgress.PERSONAL_INFORMATION}`]: 'personal-information',
  [`/join/${JoinProgress.UPLOAD_IDENTIFICATION}`]: 'upload-identification',
  [`/join/${JoinProgress.OCR_PROCESSING}`]: 'kyc-review',
  [`/join/${JoinProgress.OCR_RESULT}`]: 'confirm-personal-information',
  [`/join/${JoinProgress.MANUAL_REVIEW}`]: 'manual-review',
  '/hashtag': 'recommended-hashtag',
  [`/age-verify/${JoinProgress.UPLOAD_IDENTIFICATION}`]:
    'user-upload-identification',
  [`/age-verify/${JoinProgress.OCR_PROCESSING}`]: 'user-kyc-review',
  '/post/:messageId': 'post-detail',
  '/post/:messageId/clip/:range': 'clip-shorts-detail',
  '/feed': 'feed',
  [`/manage-profile/${ManageProfileType.ACCOUNT}`]: 'manage-profile-account',
  [`/manage-profile/${ManageProfileType.MANAGE}`]: 'manage-profile-manage',
  '/manage-profile/display-name': 'my-profile-edit-display-name',
  '/manage-profile/biography': 'my-profile-edit-biography',
  '/manage-profile/account-linking': 'my-profile-account-linking',
  '/manage-profile/account-linking/web-authn': 'my-profile-webauthn-link',
  '/manage-profile/blocked-list': 'my-profile-blocked-list',
  '/manage-profile/credit-card': 'my-profile-credit-card-information',
  '/manage-profile/credit-card/billing-email': 'my-profile-billing-email',
  '/manage-profile/receipt': 'my-profile-receipt',
  '/manage-profile/receipt/information': 'my-profile-receipt-information',
  [`/me/${ProfileListType.LIVESTREAM_SESSION}/:sessionId`]:
    'my-profile-livestream-session-list',
  [`/me/${ProfileListType.LIVESTREAM_SESSION}/:sessionId/leaderboard`]:
    'my-profile-livestream-session-leaderboard',
  '/archive': 'archive',
  '/revenue': 'revenue',
  '/revenue/report/daily': 'revenue-daily',
  '/revenue/report/fanleaderboard': 'revenue-fan-leaderboard',
  '/revenue/report/billing': 'revenue-billing',
  '/journal': 'journal',
  '/addvalue': 'addvalue',
  '/login': 'login',
  '/tos': 'tos',
  '/privacy': 'privacy',
  '/user-event': 'user-campaign-page',
  '/following': 'home-following',
};

/**
 * Get mixpanel view id
 * @param {string} pathname - pathname.
 * @return {string|null} Return mixpanel view id if matched.
 */
export const getMixpanelViewId = ({ pathname }) => {
  const { viewId } = getMixpanelViewIdAndPathParameter({
    pathname,
  });
  return viewId ? viewId : null;
};

/**
 * Get mixpanel view id and path parameter
 * @param {string} pathname - pathname.
 * @return {object} Return mixpanel view id and path parameter if matched.
 */
export const getMixpanelViewIdAndPathParameter = ({ pathname }) => {
  if (!pathname) return {};

  const patterns = Object.keys(VIEW_PATH_MAP);

  for (let i = 0; i < patterns.length; i++) {
    const pattern = patterns[i];
    let viewId = VIEW_PATH_MAP[pattern];

    const match = matchPath({ path: pattern, end: true }, pathname);
    if (match != null) {
      const { messageId, productId, category, userId, username } = match.params;
      const isProfile = /^user-profile/i.test(viewId);
      if (isProfile) {
        const isMe =
          (meId != null && meId === userId) ||
          (meUsername != null && meUsername === username);
        // Replace `user-profile` view id to `my-profile` if is same user.
        if (isMe) {
          viewId = viewId.replace(/^user-profile/i, 'my-profile');
        }
      }
      const result = {
        viewId,
        extraProps: {},
      };
      if (messageId) {
        result.messageId = messageId;
      }
      if (productId) {
        result.extraProps = {
          ...result.extraProps,
          ['product.id']: productId,
        };
      }
      if (category) {
        result.extraProps = {
          ...result.extraProps,
          ['discover.category']: category,
        };
      }
      return result;
    }
  }

  return {};
};

/**
 * Set me data.
 * @param {object} payload - payload object
 */
export const setMixpanelViewIdMeData = (payload = {}) => {
  Object.entries(payload).forEach(([key, value]) => {
    switch (key) {
      case 'meId': {
        meId = value;
        break;
      }
      case 'meUsername': {
        meUsername = value;
        break;
      }
    }
  });
};

export default getMixpanelViewId;
