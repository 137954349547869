// unsubscribeNotificationChannel.js
'use strict';
import * as MessageTypes from '../serviceWorker/AppMessageTypes.js';
import { sendMessageToSW } from '../serviceWorker/helpers.js';

/**
 * Unsubscribe notification channel
 * @kind action
 * @param {[string]} {configIds} - array of all config ids.
 * @return {Promise} Action promise.
 */
const unsubscribeNotificationChannel =
  ({ configIds = [] }) =>
  async dispatch => {
    configIds.forEach(configId => {
      sendMessageToSW({
        type: MessageTypes.UNSUBSCRIBE_NOTIFICATION_CHANNEL,
        payload: {
          configId,
        },
      });
    });
    return dispatch({ type: '' });
  };

export default unsubscribeNotificationChannel;
