// uploadConstants.js
'use strict';

// TODO: change to UploadJobType
export const Entry = {
  POST: 'post',
  STORY: 'story',
  LIVESTREAM: 'livestream',
  FLIX: 'flix',
  KYC: 'kyc',
  ME: 'me',
  CLIP: 'clip',
  CHAT: 'chat',
};

export const Priority = {
  [Entry.FLIX]: 1,
  [Entry.POST]: 1,
  [Entry.STORY]: 1,
  [Entry.KYC]: 1,
  [Entry.ME]: 1,
  [Entry.CLIP]: 1,
  [Entry.CHAT]: 2,
};

export const Status = {
  PENDING: 'pending',
  DELETING: 'deleting',
  FAILED: 'failed',

  UPLOAD_STARTED: 'upload.started',
  UPLOAD_FAILED: 'upload.failed',
  UPLOAD_COMPLETED: 'upload.completed',
  PROCESSING_STARTED: 'processing.started',
  PROCESSING_FAILED: 'processing.failed',
  PROCESSING_COMPLETED: 'processing.completed',
  REVIEW_STARTED: 'review.started',
  REVIEW_FAILED: 'review.failed',
  REVIEW_COMPLETED: 'review.completed',
  DELIVERY_STARTED: 'delivery.started',
  DELIVERY_FAILED: 'delivery.failed',
  DELIVERY_COMPLETED: 'delivery.completed',
};

export const PostDraftField = {
  TYPING_HASHTAG: 'typingHashtag',
  IS_FOCUS_ON_INPUT: 'isFocusOnInput',
  ENTRY: 'entry',
  UPLOAD_JOB_ID: 'uploadJobId',
  TITLE: 'title',
  CAPTION: 'caption',
  UNLOCK_PRICE: 'unlockPrice',
  IS_AUP_CHECKED: 'isAupChecked',
  MESSAGE_ID: 'messageId',
  IS_SCHEDULE_ENABLED: 'isScheduleEnabled',
  SCHEDULED_AT_UNIX: 'scheduledAtUnix',
  IS_LOADING: 'isLoading',
};

export const HookStatus = {
  RUNNING: 'running',
  COMPLETED: 'completed',
  FAILED: 'failed',
};
