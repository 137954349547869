// postMessageToSwagApp.js
'use strict';
import {
  getIsOnAndroidWebview,
  getIsOnIOSWebview,
} from '../resource/getUserAgent.js';
import { push, goBack } from 'redux-first-history';

/**
 * Post message to SWAG app.
 * @kind action
 * @param {string} {message} - passing message to mobile
 * @param {string} {[fallbackRouteInWebApp]} - when the mobile app API is unavaliable. will fallback to the route
 * @return {Promise} Action promise.
 */
const postMessageToSwagApp =
  ({ message, fallbackRouteInWebApp }) =>
  async dispatch => {
    if (!message) return false;

    if (getIsOnAndroidWebview()) {
      // WebApp is created from app
      // eslint-disable-next-line
      if (typeof WebApp !== 'undefined') return WebApp.postMessage(message);
    }
    if (getIsOnIOSWebview()) {
      // WebApp is created from app
      return window?.webkit?.messageHandlers?.WebApp.postMessage(message);
    }

    if ('closeWebView' === message) {
      if ('home' === fallbackRouteInWebApp) {
        return dispatch(push('/'));
      } else {
        return dispatch(goBack());
      }
    }

    if (fallbackRouteInWebApp) {
      return dispatch(push(fallbackRouteInWebApp));
    }
  };

export default postMessageToSwagApp;
