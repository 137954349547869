// setUploadJobStatus.js
'use strict';
import {
  REMOVE_LIST_ITEMS,
  REMOVE_OPERATION_DATA,
  MERGE_OPERATION_DATA,
} from '../ActionTypes.js';

import processUploadJob from '../action/processUploadJob.js';

import getMessageData from '../selector/getMessageData.js';
import getOperationData from '../selector/getOperationData.js';

import { Status as UploadStatus } from '../resource/uploadConstants.js';
import { upload as uploadDebug } from '../resource/debug.js';

const uploadLog = uploadDebug.extend('log:action/setUploadJobStatus.js');

/**
 * Set upload status
 * @kind action
 * @param {string} {uploadJobId} - upload job id.
 * @param {string} {status} - upload status.
 * @param {string} {failedReason} - upload failed reason.
 * @return {Promise} Action promise.
 */
const setUploadJobStatus =
  ({ uploadJobId, status, failedReason }) =>
  async (dispatch, getState) => {
    uploadLog('execute setUploadJobStatus', {
      uploadJobId,
      status,
      failedReason,
    });
    dispatch({
      type: MERGE_OPERATION_DATA,
      payload: {
        selectPath: ['uploadJob', uploadJobId],
        data: {
          status,
          reason: failedReason,
        },
      },
    });
    switch (status) {
      case UploadStatus.UPLOAD_FAILED:
      case UploadStatus.UPLOAD_COMPLETED: {
        return dispatch(processUploadJob());
      }
      case UploadStatus.PROCESSING_COMPLETED:
      case UploadStatus.DELIVERY_COMPLETED: {
        const messageId = getOperationData(
          getState(),
          ['uploadJob', uploadJobId],
          'messageId'
        );
        const scheduledAtUnix = getMessageData(
          getState(),
          messageId,
          'scheduledAtUnix'
        );
        // Needs to remove local draft if is scheduled post when receive `processing.completed`.
        if (!scheduledAtUnix && UploadStatus.PROCESSING_COMPLETED === status) {
          return;
        }
        dispatch({
          type: REMOVE_LIST_ITEMS,
          payload: {
            selectPath: ['uploadJob'],
            itemIds: [uploadJobId],
          },
        });
        return dispatch({
          type: REMOVE_OPERATION_DATA,
          payload: {
            selectPath: ['uploadJob', uploadJobId],
          },
        });
      }
    }
  };

export default setUploadJobStatus;
