// mixpanel.js
'use strict';
import { v4 as uuidv4 } from 'uuid';

import { mixpanel as mixpanelDebug } from '../resource/debug.js';
import env from '../resource/env.js';
import {
  getGenericOSName,
  getDeviceType,
  getUserAgent,
} from '../resource/getUserAgent.js';
import i18n from '../resource/i18n.js';
import {
  getMixpanelViewId,
  getMixpanelViewIdAndPathParameter,
} from '../resource/getMixpanelViewId.js';
import { DrmRobustnessPriority } from '../resource/drmConstants.js';
import loadScriptTag from '../resource/loadScriptTag.js';

const mixpanelLog = mixpanelDebug.extend('log:mixpanel');
let pubSubController;

const MIXPANEL_SCRIPT = `(function(f,b){if(!b.__SV){var e,g,i,h;window.mixpanel=b;b._i=[];b.init=function(e,f,c){function g(a,d){var b=d.split(".");2==b.length&&(a=a[b[0]],d=b[1]);a[d]=function(){a.push([d].concat(Array.prototype.slice.call(arguments,0)))}}var a=b;"undefined"!==typeof c?a=b[c]=[]:c="mixpanel";a.people=a.people||[];a.toString=function(a){var d="mixpanel";"mixpanel"!==c&&(d+="."+c);a||(d+=" (stub)");return d};a.people.toString=function(){return a.toString(1)+".people (stub)"};i="disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking start_batch_senders people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove".split(" ");
for(h=0;h<i.length;h++)g(a,i[h]);var j="set set_once union unset remove delete".split(" ");a.get_group=function(){function b(c){d[c]=function(){call2_args=arguments;call2=[c].concat(Array.prototype.slice.call(call2_args,0));a.push([e,call2])}}for(var d={},e=["get_group"].concat(Array.prototype.slice.call(arguments,0)),c=0;c<j.length;c++)b(j[c]);return d};b._i.push([e,f,c])};b.__SV=1.2;e=f.createElement("script");e.type="text/javascript";e.async=!0;e.src="undefined"!==typeof MIXPANEL_CUSTOM_LIB_URL?MIXPANEL_CUSTOM_LIB_URL:"file:"===f.location.protocol&&"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\\/\\//)?"https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js":"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js";g=f.getElementsByTagName("script")[0];g.parentNode.insertBefore(e,g)}})(document,window.mixpanel||[]);`;

/** Will Mixpanel events go to dev envrionment */
export const isDevEnvironment =
  typeof window !== 'undefined' &&
  /localhost|.app\.swag\.live$/.test(window.location.hostname);

export const ButtonId = {
  Landing: {
    ButtonLandingLogin: 'button-landing-login',
    ButtonBirthdayChange: 'button-birthday-change',
    LandingPageCta: 'landing-page-cta',
  },
  All: {
    ButtonHidePassword: 'button-hide-password',
    ButtonDisplayPassword: 'button-display-password',
    ButtonMuted: 'button-muted',
    ButtonVolume: 'button-volume',
    ButtonLogout: 'button-logout',
    ButtonCancel: 'button-cancel',
    ButtonOnlineService: 'button-online-service',
    ButtonDiamondShop: 'button-diamond-shop',
    ButtonGift: 'button-gift',
    ButtonGiftChatroom: 'button-chatroom-gifts',
    ButtonGiftLiveStream: 'button-livestream-gifts',
    ButtonRecord: 'button-record',
    ButtonMessageSend: 'button-message-send',
    ButtonSettings: 'button-settings',
    ButtonClose: 'button-close',
    ButtonBack: 'button-back',
    ButtonMore: 'button-more',
    ButtonFollow: 'button-follow',
    ButtonUnfollow: 'button-unfollow',
    ButtonChat: 'button-chat',
    ButtonUnlock: 'button-unlock',
    ButtonShare: 'button-share',
    ButtonTempLivestream: 'button-temp-livestream',
    ButtonTempFreezone: 'button-home-freezone',
    TabStory: 'tab-story',
    TabFlix: 'tab-flix',
    TabImage: 'tab-image',
    TabLeaderboardNow: 'tab-leaderboard-now',
    TabLeaderboardHour: 'tab-leaderboard-hour',
    TabLeaderboardWeek: 'tab-leaderboard-week',
    TabLeaderboardMonth: 'tab-leaderboard-month',
    TabChatroom: 'tab-chatroom',
    ButtonFlixCard: 'button-flix-card',
    ButtonStoryCard: 'button-story-card',
    ButtonCardAvatar: 'button-card-avatar',
    ButtonPostCard: 'button-post-card',
    ButtonLiveStreamCard: 'button-livestream-card',
    ButtonLivestreamMore: 'button-livestream-more',
    ButtonCpCard: 'button-cp-card',
    ButtonKycUnblur: 'button-kyc-unblur',
    ButtonKycSticker: 'button-kyc-sticker',
    ButtonHamburger: 'button-hamburger',
    ButtonEnterSWAG: 'button-enter-swag',
    ButtonExitSWAG: 'button-exit-swag',
    ButtonAgeVerifyBanner: 'button-age-verify-banner',
    ButtonFreeWatch: 'button-free-watch',
    ButtonCheck: 'button-check',
    ButtonSubscribe: 'button-subscribe',
    ButtonUnsubscribe: 'button-unsubscribe',
    ButtonTextTranslate: 'button-text-translate',
    ButtonTextOriginal: 'button-text-original',
    ButtonDelete: 'button-delete',
    ButtonCopyCode: 'button-copy-code',
    ButtonCopyLink: 'button-copy-link',
    ButtonReport: 'button-report',
    ButtonRestrict: 'button-restrict',
    ButtonUnblock: 'button-unblock',
    ButtonDiscard: 'button-discard',
    ButtonConfirm: 'button-confirm',
    ButtonNext: 'button-next',
    ButtonUsername: 'button-username',
    ButtonSave: 'button-save',
    ButtonLeave: 'button-leave',
    ButtonResubmit: 'button-resubmit',
    ButtonGiftItem: 'button-gift-item',
    ButtonOpenSidebar: 'button-open-sidebar',
    ButtonCloseSidebar: 'button-close-sidebar',
    ButtonHome: 'button-home',
    ButtonLike: 'button-like',
    ButtonDislike: 'button-dislike',
    ButtonSortingOption: 'button-sorting-option',
    ButtonFilterOption: 'button-filter-option',
    ButtonFilterConfirm: 'button-filter-confirm',
    ButtonFilterReset: 'button-filter-reset',
    ButtonPin: 'button-pin',
    ButtonUnpin: 'button-unpin',
    ButtonHiddenMassage: 'button-hidden-massage',
    ButtonVideoClip: 'button-video-clip',
    ButtonExpand: 'button-expand',
    ButtonPurchase: 'button-purchase',
    ButtonInformation: 'button-information',
    ButtonUserEvent: 'button-user-event',
    ButtonUserBadge: 'button-user-badge',
    ButtonVerifyNow: 'button-verify-now',
  },
  Home: {
    ButtonCovergirlFollow: 'button-covergirl-follow',
    ButtonFirstMessageSend: 'button-first-message-send',
    ButtonCovergirlMessageSend: 'button-covergirl-message-send',
    ButtonFirstUnlock: 'button-first-unlock',
    ButtonHomeLeaderboardCard: 'button-home-leaderboard-card',
    ButtonFreeFeedChat: 'button-free-feed-chat',
    ButtonChannelLiveStream: 'button-channel-livestream',
    ButtonSearch: 'button-search',
    ButtonAutoComplate: 'button-autocomplete',
    ButtonChannelFreezone: 'button-channel-freezone',
    ButtonNotificationCenter: 'button-notification-center',
    ButtonHomeTabFeatures: 'button-home-tab-features',
    ButtonHomeTabFeed: 'button-home-tab-feed',
    ButtonHomeTabVideo: 'button-home-tab-video',
    ButtonHomeTabStory: 'button-home-tab-story',
    ButtonTrendingHashtag: 'button-trending-hashtag',
    ButtonHomeBanners: 'button-home-banners',
    ButtonVideoBanners: 'button-video-banners',
  },
  Tab: {
    TabButtonShorts: 'tab-button-shorts',
    TabButtonDiscover: 'tab-button-discover',
    TabButtonHome: 'tab-button-home',
    TabButtonSearch: 'tab-button-search',
    TabButtonChat: 'tab-button-chat',
    TabButtonProfile: 'tab-button-profile',
    TabButtonCreate: 'tab-button-create',
    TabFlixDetailCp: 'tab-flix-detail-cp',
    TabFlixDetailRecommend: 'tab-flix-detail-recommend',
    TabButtonLivestream: 'tab-button-livestream',
    TabButtonEvent: 'tab-button-event',
    TabLotteryEvent: 'tab-lottery-event',
    TabDatingAssuranceEvent: 'tab-dating-assurance-event',
    TabSearchAll: 'tab-search-all',
    TabSearchVideo: 'tab-search-video',
    TabSearchCreator: 'tab-search-creator',
    TabSearchStory: 'tab-search-story',
    TabSearchHashtag: 'tab-search-hashtag',
    TabButtonFeed: 'tab-button-feed',
    TabButtonHamburger: 'tab-button-hamburger',
    TabButtonLeaderboard: 'tab-button-leaderboard',
    TabFollowing: 'tab-following',
    TabExplore: 'tab-explore',
    TabBrowse: 'tab-browse',
    TabPublished: 'tab-published',
    TabUnpublished: 'tab-unpublished',
  },
  Search: {
    SearchRecentHashtag: 'search-recent-hashtag',
    SearchTrendingHashtag: 'search-trending-hashtag',
    ButtonMoreFlix: 'button-more-flix',
    ButtonMoreCreator: 'button-more-creator',
    ButtonMoreStory: 'button-more-story',
    ButtonMoreHashtag: 'button-more-hashtag',
  },
  Setting: {
    ButtonDownloadSchatIos: 'button-download-schat-ios',
    ButtonDiamondShop: 'button-diamond-shop',
    ButtonPrepaidSend: 'button-prepaid-send',
    ButtonSwagBlog: 'button-swag-blog',
    ButtonTopup: 'topup-setting-autopay ',
    ButtonRegion: 'button-region',
    ButtonLanguage: 'button-language',
    ButtonLanguageOption: 'button-language-option',
    ButtonProfile: 'button-profie',
    ButtonKYC: 'button-kyc',
    ButtonHamburgerClose: 'button-hamburger-close',
    ButtonHamburgerArchived: 'button-hamburger-archived',
    ButtonAgeVerifyHamburger: 'button-age-verify-hamburger',
    ButtonExchange: 'button-exchange',
    ButtonGetReward: 'button-get-reward',
    ButtonSettings: 'button-settings',
    ButtonSwagOpening: 'button-swag-opening',
    ButtonBecomeAModel: 'button-become-a-model',
    ButtonChannelVip: 'button-channel-vip',
    ButtonLogin: 'button-setting-login',
    ButtonLogout: 'button-setting-logout',
    ButtonJournal: 'button-journal',
    ButtonConnectEmail: 'button-connect-email',
    ButtonHamburgerEcommerce: 'button-hamburger-ecommerce',
    ButtonTos: 'button-tos',
    ButtonPrivacyPolicy: 'button-privacy-policy',
    ButtonDrmPanel: 'button-drm-panel',
    ButtonAboutSwag: 'button-about-swag',
    Button2257: 'button-2257',
    ButtonFaq: 'button-faq',
    ButtonContactSupport: 'button-contact-support',
    ButtonDebugPanel: 'button-debug-panel',
    ButtonCareer: 'button-career',
    ButtonSwagInstagram: 'button-swag-instagram',
    ButtonSwagYoutube: 'button-swag-youtube',
    ButtonSwagTwitter: 'button-swag-twitter',
    ButtonSwagDiscord: 'button-swag-discord',
    ButtonSwagTelegram: 'button-swag-telegram',
    ButtonSwagTiktok: 'button-swag-tiktok',
    ButtonRta: 'button-rta',
    ButtonAsacp: 'button-asacp',
    ButtonDmca: 'button-dmca',
    ButtonSwagAffiliateProgram: 'button-swag-affiliate-program',
    ButtonMailToMarketing: 'button-mail-to-marketing',
    ButtonChangeEmail: 'button-change-email',
    ButtonDisconnect: 'button-disconnect',
    ButtonUserToUserReferralBanner: 'button-user-to-user-referral-banner',
    ButtonHelpCenter: 'button-help-center',
    ButtonVipLevelCTA: 'vip-level-cta',
    ButtonVipLevelToggle: 'vip-level-toggle',
    ButtonVipLevelEdit: 'vip-level-edit',
    ButtonPushNotifications: 'button-push-notifications',
  },
  DiamondShop: {
    ButtonRegisterNewCreditcard: 'topup-add-new-card-confirm',
    ButtonSwitchCurrency: 'button-switch-currency',
    ButtonServiceLineAt: 'button-service-line-at',
    ButtonServiceWhatsapp: 'button-service-whatsapp',
    ButtonServiceMessenger: 'button-service-messenger',
    ButtonPaymentCreditcard: 'button-payment-creditcard',
    ButtonPaymentMaster: 'button-payment-master',
    ButtonPaymentMarket: 'button-payment-market',
    ButtonPaymentGashPin: 'button-payment-gash-pin',
    ButtonPaymentGashWallet: 'button-payment-gash-wallet',
    ButtonPaymentWebcash: 'button-payment-webcash',
    ButtonPaymentUnionpay: 'button-payment-unionpay',
    ButtonPaymentAddCreditcard: 'button-payment-add-creditcard',
    ButtonPaymentAlipay: 'button-payment-alipay',
    ButtonPaymentVisa: 'button-payment-visa',
    ButtonPaymentGooglePay: 'button-payment-google-pay',
    ButtonPaymentApplePay: 'button-payment-apple-pay',
    ButtonPaymentPrepaidcard: 'button-payment-prepaidcard',
    ButtonPaymentJcb: 'button-payment-jcb',
    ButtonPaymentDiscover: 'button-payment-discover',
    ButtonPaymentAmericanExpress: 'button-payment-american-express',
    ButtonPaymentWechat: 'button-payment-wechat',
    ButtonPaymentLinepay: 'button-payment-linepay',
    ButtonPaymentAtm: 'button-payment-atm',
    ButtonPaymentWebAtm: 'button-payment-web-atm',
    ButtonPaymentDc: 'button-payment-dc',
    ButtonPay: 'button-pay',
    ButtonReceive: 'button-receive',
    ButtonPaymentMethodToggle: 'button-payment-method-toggle',
    ButtonPaymentMethodItem: 'button-payment-method-item',
    ButtonShopProduct: 'button-shop-product',
    ButtonOrderSummary: 'order-summary',
    ButtonPayWith: 'button-pay-with',
  },
  LoginRegister: {
    ButtonToSetPassword: 'button-to-set-password',
    ButtonSwitchAccount: 'button-switch-account',
    ButtonContinueRegister: 'button-continue-register',
    ButtonBackToLogin: 'button-backtologin',
    ButtonForgotPassword: 'button-forgot-password',
    ButtonLoginPage: 'button-login-page',
    ButtonLoginApple: 'button-login-apple',
    ButtonLoginGoogle: 'button-login-google',
    ButtonLoginFacebook: 'button-login-facebook',
    ButtonLoginTwitter: 'button-login-twitter',
    ButtonLoginPhone: 'button-login-phone',
    ButtonLoginMail: 'button-login-mail',
    ButtonLoginDiscord: 'button-login-discord',
    ButtonLoginPassword: 'button-login-password',
    ButtonLoginWebAuthn: 'button-login-webauthn',
    ButtonLogin: 'button-login',
    ButtonSignup: 'button-signup',
    ButtonSignupApple: 'button-signup-apple',
    ButtonSignupGoogle: 'button-signup-google',
    ButtonSignupTwitter: 'button-signup-twitter',
    ButtonSignupPhone: 'button-signup-phone',
    ButtonSignupMail: 'button-signup-mail',
    ButtonSignupDiscord: 'button-signup-discord',
    ButtonSignupLogin: 'button-signup-login',
    ButtonVerifyLater: 'button-verify-later',
    ButtonChangeEmail: 'button-change-email',
    ButtonOtherVerifyOptions: 'button-other-verify-options',
    ButtonVerifyPurchase: 'button-verify-purchase',
    ButtonVerifyCreditCard: 'button-verify-credit-card',
    ButtonVerifyTwid: 'button-verify-twid',
  },
  EventPopup: {
    EventPopupLink: 'event-popup-link',
  },
  Toast: {
    BindMail: 'button-bind-mail',
  },
  Modal: {
    ButtonDownloadSchat: 'button-download-schat',
    ButtonGeneralRemoval: 'general-removal',
  },
  Chatroom: {
    ButtonAudioPlay: 'button-audio-play',
    ButtonAudioStop: 'button-audio-stop',
    ButtonCancelRecord: 'button-cancel-record',
    ButtonStopRecord: 'button-stop-record',
    ButtonFirstMessage: 'button-first-message',
    ButtonCpEvent: 'button-cp-event',
    ButtonJoinEvent: 'button-join-event',
    ChatAnnounce: 'chat-announce',
    ButtonChatFeed: 'button-chat-feed',
    ButtonChatroomSearchCancel: 'button-chatroom-search-cancel',
    ButtonTextTranslate: 'button-text-translate',
    ButtonTextCopy: 'button-text-copy',
    ButtonMediaLibrary: 'button-media-library',
    ButtonGoToProfile: 'button-go-to-profile',
    ButtonPinChat: 'button-pin-chat',
    ButtonUnpinChat: 'button-unpin-chat',
    ButtonCamera: 'button-camera',
    ButtonNewChatroom: 'button-new-chatroom',
    ButtonSearch: 'button-search',
  },
  LiveStream: {
    ButtonUserProfileLiveStream: 'button-user-profile-livestream',
    CountDownToDiamondShop: 'countdown-to-diamond-shop',
    ButtonTurnToPrivate: 'button-turn-to-private',
    ButtonGiftLeaderboard: 'button-gift-leaderboard',
    ButtonGiftLeaderboardTop10: 'button-gift-leaderboard-top10',
    ButtonLiveStreamChat: 'button-livestream-chat',
    ButtonCommandItem: 'button-command-item',
    ButtonLovenseCommandItem: 'button-lovense-command-item',
    ButtonCommandList: 'button-command-list',
    ButtonLovenseCommandList: 'button-lovense-command-list',
    ButtonShowSummary: 'button-show-summary',
    ButtonShowPurchaseModal: 'button-show-purchase-modal',
    ButtonShowLastCallPopUp: 'button-show-last-call-popup',
    ButtonShowCompletePopUp: 'button-show-complete-popup',
    CommandHint: 'command-hint',
    ButtonPinSentence: 'button-pin-the-sentence',
    ButtonLowLatencyOn: 'button-low-latency-on',
    ButtonLowLatencyOff: 'button-low-latency-off',
    ButtonLivestreamFilter: 'button-livestream-filter',
    ButtonLivestreamSorting: 'button-livestream-sorting',
    ButtonLivestreamResetFilter: 'button-livestream-reset-filter',
    ButtonLivestreamAd1: 'button-livestream-ad-1',
    ButtonLivestreamAd2: 'button-livestream-ad-2',
    ButtonShowDetailSignup: 'button-show-detail-signup',
    ButtonPopupChatroom: 'button-popup-chatroom',
    ButtonReloadPlayer: 'button-reload-player',
    ButtonSicboHistory: 'button-sicbo-history',
    ButtonSicboHelp: 'button-sicbo-help',
    ButtonSicboGame: 'button-sicbo-game',
    ButtonDragonTigerFightGame: 'button-dragon-tiger-fight-game',
    ButtonGame: 'button-game',
    ButtonSicboBetBig: 'button-sicbo-bet-big',
    ButtonSicboBetSmall: 'button-sicbo-bet-small',
    ButtonSicboBetAnytriple: 'button-sicbo-bet-anytriple',
    ButtonOfflineToProfile: 'button-offline-to-profile',
  },
  Detail: {
    PlayerControlsFullscreen: 'player-controls-fullscreen',
    PlayerControlsPlay: 'player-controls-play',
    PlayerControlsPause: 'player-controls-pause',
    PlayerControlsSeekbar: 'player-controls-seekbar',
    playerControlsSubtitle: 'player-controls-subtitle',
    ButtonWatchFullVideo: 'button-watch-full-video',
    ButtonWatchFullVideoFinished: 'button-watch-full-video-finished',
    ButtonRelatedVideo: 'button-related-video',
    ButtonInfo: 'button-info',
    ButtonRecommendFlix: 'button-recommend-flix',
    ButtonRecommendFlixAvatar: 'button-recommend-flix-avatar',
    ButtonAgeVerifyFlixDetail: 'button-age-verify-flix-detail',
    ButtonFreeDetailSignup: 'button-free-detail-signup',
    ButtonDetailSignup: 'button-detail-signup',
    ButtonSingleUnlocked: 'button-single-unlocked',
    ButtonGoToMessagePack: 'button-go-to-message-pack',
  },
  Tinder: {
    ButtonChangeList: 'button-change-list',
    ButtonLangZh: 'button-lang-zh',
    ButtonLangEn: 'button-lang-en',
    ButtonLangJp: 'button-lang-jp',
    ButtonOepnBio: 'button-open-bio',
    ButtonCloseBio: 'button-close-bio',
    ButtonLike: 'button-like',
    ButtonPass: 'button-pass',
    ButtonBackToSwag: 'button-back-to-swag',
    ButtonGoToChat: 'button-go-to-chat',
    ButtonContinueExplore: 'button-continue-explore',
  },
  Event: {
    ItemLotteryEvent: 'item-lottery-event',
    ButtonAskForDetails: 'button-ask-for-details',
    ButtonCpEventChat: 'button-cp-event-chat',
  },
  Freezone: {
    ButtonPublish: 'button-publish',
    ButtonUploadVideoIcon: 'button-upload-video-icon',
    TabPro: 'tab-pro',
    TabAmateur: 'tab-amateur',
  },
  Topup: {
    ButtonTopupTurnOffCancel: 'topup-turn-off-cancel',
    ButtonTopupPaymentMethodRemove: 'topup-removal',
  },
  Leaderboard: {
    ButtonLeaderboardUser: 'button-leaderboard-user',
    ButtonLeaderboardTab: 'button-leaderboard-tab',
  },
  Notification: {
    NotificationItem: 'notification-item',
    ButtonNotificationLivestream: 'button-notification-livestream',
  },
  Verification: {
    ButtonBindCreditCard: 'button-bind-credit-card',
    ButtonCustomerServiceVerify: 'button-customer-service-verify',
    ButtonFirstPurchase: 'button-first-purchase',
    ButtonUserAgeVerify: 'button-user-age-verify',
  },
  Join: {
    ButtonBecomeCreator: 'button-become-creator',
    ButtonTakePhoto: 'button-take-photo',
    ButtonCapture: 'button-capture',
    ButtonVerifyNow: 'button-verify-now',
    ButtonDownloadApp: 'button-download-app',
  },
  Backpack: {
    ButtonBackpack: 'button-backpack',
    ButtonTicketRedeem: 'button-ticket-redeem',
    ButtonReceive: 'button-receive',
    TabTicket: 'tab-ticket',
  },
  Subscription: {
    ButtonSubscribe: 'button-subscribe',
    ButtonUnlockPost: 'button-unlock-post',
  },
  Post: {
    ButtonPostCommentCount: 'button-post-comment-count',
    ButtonPostComment: 'button-post-comment',
    ButtonPostLikeCount: 'button-post-like-count',
    ButtonPostCommentViewMore: 'button-post-comment-view-more',
    ButtonSummary: 'button-summary',
    ButtonPostMedia: 'button-post-media',
    ButtonPostVideoReplay: 'Button-post-video-replay',
    ButtonPostTextMore: 'button-post-text-more',
  },
  Upload: {
    ButtonUpload: 'button-upload',
    ButtonCancelUpload: 'button-cancel-upload',
    ButtonCameraLivestream: 'button-camera-livestream',
    ButtonStory: 'button-story',
    ButtonPost: 'button-post',
    ButtonPublish: 'button-publish',
    ButtonPreview: 'button-preview',
    ButtonPostPhotos: 'button-post-photos',
    ButtonPostVideo: 'button-post-video',
    ButtonUploadFailedTryAgain: 'button-upload-failed-try-again',
    ButtonFansId: 'button-fans-id',
    SchedulePostToggleOn: 'schedule-post-toggle-on',
    SchedulePostToggleOff: 'schedule-post-toggle-off',
  },
  BankAccount: {
    ButtonEditPaymentMethod: 'button-edit-payment-method',
    ButtonSave: 'button-save',
  },
  Profile: {
    ButtonProfileHome: 'button-profile-home',
    ButtonEditProfileDone: 'button-edit-profile-done',
    ButtonEditProfileChangePhoto: 'button-edit-profile-change-photo',
    ButtonManageProfile: 'button-manage-profile',
    ButtonManageWebAuthn: 'button-manage-webauthn',
    ButtonLinkWebAuthn: 'button-link-webauthn',
    ButtonProfilePost: 'button-profile-post',
    ButtonProfileVideo: 'button-profile-video',
    ButtonProfileImage: 'button-profile-image',
    ButtonProfileStory: 'button-profile-story',
    ButtonProfileLive: 'button-profile-live',
    ButtonProfileShorts: 'button-profile-shorts',
    ButtonPostClick: 'post-click',
    ButtonCreatePost: 'button-create-post',
    ButtonCreateVideo: 'button-create-video',
    ButtonCreateImage: 'button-create-image',
    ButtonCreateStory: 'button-create-story',
    ButtonCreateLive: 'button-create-live',
    ButtonManage: 'button-manage',
    ButtonAccount: 'button-account',
    ButtonKycBadge: 'button-kyc-badge',
    ButtonChatPrice: 'button-chat-price',
    ButtonSubscriptionPrice: 'button-subscription-price',
    ButtonDisplayName: 'button-display-name',
    ButtonBiography: 'button-biography',
    ButtonFollowers: 'button-followers',
    ButtonFollowing: 'button-following',
    ButtonAdvancedManagement: 'button-advanced-management',
    ButtonSubscribers: 'button-subscribers',
    ButtonSubscribed: 'button-subscribed',
    ButtonAccountLink: 'button-account-link',
    ButtonPasswordSetting: 'button-password-setting',
    ButtonBlockList: 'button-block-list',
    ButtonPaymentMethodList: 'button-payment-method-list',
    ButtonReceiptSetting: 'button-receipt-setting',
    ButtonTotalEarnings: 'button-total-earnings',
    ButtonSubscriptionEarnings: 'button-subscription-earnings',
    ButtonContest: 'button-contest',
    ButtonSubscriberDetail: 'button-subscriber-detail',
    ButtonSubscribedDetail: 'button-subscribed-detail',
    ButtonContract: 'button-contract',
    ButtonRelevantDocuments: 'button-relevant-documents',
    ButtonWithdrawMethod: 'button-withdraw-method',
    ButtonWatchLivestream: 'button-watch-livestream',
    ButtonWatchStory: 'button-watch-story',
    ButtonWatchAvatar: 'button-watch-avatar',
    ButtonProfileSorting: 'button-profile-sorting',
    ButtonUnpublishedCard: 'button-unpublished-card',
    ButtonDeactivate: 'button-deactivate',
  },
  Hashtag: {
    ButtonHashtagSorting: 'button-hashtag-sorting',
    ButtonUserHashtag: 'button-user-hashtag',
  },
  Shorts: {
    ShortsCaptionOpen: 'shorts-caption-open',
    ShortsCaptionClose: 'shorts-caption-close',
    ButtonShortsToLivestream: 'button-shorts-to-livestream',
    ButtonVisitorShortsToLivestream: 'button-visitor-shorts-to-livestream',
  },
  Referral: {
    CurrentMonth: 'current-month',
    PastTwelveMonth: 'past-twelve-month',
    PastCancel: 'past-cancel',
  },
  Browse: {
    ButtonGoExplore: 'button-go-explore',
  },
};

export const ElementId = {
  Chatroom: {
    ButtonGiftCategory: 'button-gift-category',
    GiftSlider: 'gift-slider',
  },
  DiamondShop: {
    CurrencyItem: 'currency-item',
  },
  Join: {
    ButtonBecomeCreatorCountry: 'button-become-creator-country',
    InputBecomeCreatorUsername: 'input-become-creator-username',
    InputBecomeCreatorReferralCode: 'input-become-creator-referral-code',
    InputBecomeCreatorPassword: 'input-become-creator-password',
    InputBecomeCreatorEmail: 'input-become-creator-email',
    SelectBecomeCreatorGender: 'select-become-creator-gender',
  },
  ManageProfile: 'manage-profile',
  ManageProfileAccount: {
    AccountLinks: 'account-links',
    BlockedList: 'blocked-list',
    CardInfo: 'card-info',
    DeleteAccount: 'delete-account',
    EReceipt: 'e-receipt',
    MyEReceipt: 'my-e-receipt',
  },
};

export const ModalId = {
  Landing: {
    Over18BirthdayModal: 'over-18-birthday-modal',
    LandingPage2024Iwin: 'landing-page-2024-iwin',
  },
  LoginSignup: {
    LoginEntries: 'login-entries',
    LoginEntriesCn: 'login-entries-cn',
    SignupEntries: 'signup-entries',
    SignupEntriesCn: 'signup-entries-cn',
    UsernamePasswordEnter: 'username-password-enter',
    EmailEnter: 'email-enter',
    EmailVerify: 'email-verify',
    PhoneEnter: 'phone-enter',
    PhoneCountryCodes: 'phone-country-codes',
    PhoneVerify: 'phone-verify',
    EmailRefill: 'email-refill',
    QRCode: 'qr-code',
    SwitchAccount: 'switch-account',
    BindEmailChangeBrowserRelogin: 'bind-email-change-browser-relogin',
    ForgotPassword: 'forgot-password',
    NotSetPasswordPopup: 'not-set-password-popup',
    AgeVerificationPopup: 'age-verification-popup',
  },
  WebAuthn: {
    LoginError: 'modal-webauthn-login-error',
    DuplicatedError: 'modal-webauthn-duplicated-error',
  },
  Register: {
    RegisterPage: 'register-page',
    RegisterPageTw: 'register-page-tw',
    AgeEnterTw: 'age-enter-tw',
  },
  Bind: {
    BindEmailEnter: 'bind-email-enter',
    BindEmailVerify: 'bind-email-verify',
    BindEmailFailed: 'bind-email-failed',
    BindEmailRelogin: 'bind-email-relogin',
    ModalNotYetVerified: 'modal-not-yet-verified',
  },
  DiamondShop: {
    DiamondShopDiamondPacks: 'diamond-shop-diamond-packs',
    DiamondShopSelectPaymentMethod: 'diamond-shop-select-payment-method',
    DiamondShopSelectCurrency: 'diamond-shop-select-currency',
    DiamondShopOrderUnderProcessing: 'diamond-shop-order-under-processing',
    DiamondShopOrderProcessing: 'diamond-shop-order-processing',
    DiamondShopOrderComplete: 'diamond-shop-order-complete',
    DiamondShopOrderFailed: 'diamond-shop-order-failed',
    DiamondShopOrderProcessingInapp: 'diamond-shop-order-processing-inapp',
    DiamondShopOrderCompleteInapp: 'diamond-shop-order-complete-inapp',
    DiamondShopOrderFailedInapp: 'diamond-shop-order-failed-inapp',
    DiamondShopCreditcardEnter: 'diamond-shop-creditcard-enter',
    SubtotalPayment: 'subtotal-payment',
  },
  Popup: {
    IosUnsupportedDownloadSchat: 'ios-unsupported-download-schat',
    IosDownloadSchat: 'ios-download-schat',
    AddToHomeScreen: 'add-to-home-screen',
    AndroidNotificationOpenFirst: 'android-notification-open-first',
    AndroidNotificationOpenTutorial: 'android-notification-open-tutorial',
    AgeVerification: 'age-verification',
    MyProfileEdit: 'my-profile-edit',
    EventPopup: 'event-popup',
    NotRegisterPopup: 'not-register-popup',
    NotUserPopup: 'not-user-popup',
    UnconfirmedRegisterPopup: 'unconfirmed-register-popup',
    BindMail: 'toast-bind-mail',
    GeneralRemovePopup: 'general-removal-modal',
    DrmCannotPlayPopup: 'drm-cannot-play-popup',
    AgeVerificationPopup: 'age-verification-popup',
    CardVerify: 'card-verify',
    ModalProfileMore: 'modal-profile-more',
    ModalSubscriberDetail: 'modal-subscriber-detail',
    ModalSubscribedDetail: 'modal-subscribed-detail',
    ModalMessageMore: 'modal-message-more',
    FilterPopup: 'filter-popup',
    SortingPopup: 'sorting-popup',
    UnpinReplace: 'unpin-replace',
    ModalUnpin: 'modal-unpin',
    ModalClip: 'modal-clip',
    ModalInsufficientClipDuration: 'modal-insufficient-clip-duration',
    ModalVideoLessThan60s: 'modal-video-less-than-60s',
    ModalIntercomArticle: 'modal-intercom-article',
    ModalDiscard: 'modal-discard',
    ModalMessagePackUpsell: 'modal-message-pack-upsell',
    UpdateDisplayNameFailed: 'update-display-name-failed',
    ModalMicrophoneUnsupported: 'modal-microphone-unsupported',
  },
  Prepaid: {
    PrepaidRedemption: 'prepaid-redemption',
    PrepaidConfirmPopup: 'prepaid-confirm-popup',
    PrepaidSuccessPopup: 'prepaid-success-popup',
  },
  LiveStream: {
    ToPrivateCountDownPopUp: 'to-private-countdown-popup',
    LastCallCountDownPopUp: 'last-call-countdown-popup',
    LivestreamCommandPlaylist: 'livestream-command-playlist',
    LivestreamLovenseCommandPlaylist: 'livestream-lovense-command-playlist',
    LivestreamShowPurchaseModalPerforming:
      'livestream-show-purchase-modal-performing',
    LivestreamShowPurchaseModal: 'livestream-show-purchase-modal',
    LivestreamShowLastCallPopUp: 'livestream-show-last-call-popup',
    LivestreamShowCompletePopUp: 'livestream-show-complete-popup',
    CommandHint: 'command-hint',
    LivestreamFreeLimitChat: 'livestream-free-limit-chat',
    ModalLivestreamMore: 'modal-livestream-more',
    SicBoGame: 'sic-bo-game',
    DragonTigerFight: 'dragon-tiger-fight',
    SicBoDrawResult: 'sic-bo-draw-result',
    SicBoBetWinToaster: 'sic-bo-bet-win-toaster',
    SicBoBetSummary: 'sic-bo-bet-summary',
    SicBoHelp: 'sic-bo-help',
    SicBoHistory: 'sic-bo-history',
    SicBoTutorial: 'sic-bo-tutorial',
  },
  Tinder: {
    Tinder: 'tinder',
    TinderBio: 'tinder-bio',
    TinderMatch: 'tinder-match',
    TinderEndingPage: 'tinder-ending-page',
  },
  Chatroom: {
    CpEventPage: 'cp-event-page',
    ModalTextMore: 'modal-text-more',
  },
  Settings: {
    PaymentMethodSelector: 'settings-payment-method',
    ResetPassword: 'reset-password',
    ChannelVip: 'settings-channel-vip',
    Logout: 'modal-logout',
    ModalLanguageMenu: 'modal-language-menu',
    ModalConfirmChangeVerifiedEmail: 'modal-confirm-change-verified-email',
    DisconnectSocialAccount: 'settings-disconnect-social-account',
  },
  HamburgerMenu: {
    Menu: 'modal-hamburger',
  },
  Notification: {
    NotificationCenterList: 'notification-center-list',
  },
  Topup: {
    TopupTurnOffPopup: 'topup-turnoff-modal',
    TopupPaymentMethodRemoveConfirm: 'topup-remove-creditcard-modal',
  },
  Join: {
    ModalBecomeCreator: 'modal-become-creator',
    ModalPhotoRequirement: 'modal-photo-requirement',
    ModalMaleReject: 'modal-male-reject',
    ModalVerificationNotComplete: 'modal-verification-not-complete',
    ModalDownloadApp: 'modal-download-app',
    PhotoOfYourId: 'photo-of-your-id',
    PhotoOfYourself: 'photo-of-yourself',
    CheckImageQuality: 'check-image-quality',
  },
  Backpack: {
    ModalBackpack: 'modal-backpack',
  },
  Subscription: {
    ModalSubscribe: 'modal-subscribe',
    ModalUnlockPost: 'modal-unlock-post',
  },
  Post: {
    ModalPostLikeDetail: 'modal-post-like-detail',
    ModalPostSummaryDetail: 'modal-post-summary-detail',
    ModalHiddenRequest: 'modal-hidden-request',
    ModalHiddenInfo: 'modal-hidden-info',
    ModalPublishAlert: 'modal-publish-alert',
  },
  Upload: {
    ModalCreate: 'modal-create',
    CreateEditPost: 'create-edit-post',
    CreateEditStory: 'create-edit-story',
    ModalEditBack: 'modal-edit-back',
    ModalPost: 'modal-post',
    ModalPreview: 'modal-preview',
    ModalUploadMore: 'modal-upload-more',
  },
  BankAccount: {
    UnsavedChanges: 'unsaved-changes',
  },
  Profile: {
    ChatPrice: 'chat-price',
    SubscriptionPrice: 'subscription-price',
    ModalAdvancedManagement: 'modal-advanced-management',
    RelevantDocuments: 'relevant-documents',
    Contract: 'contract',
    ModalClickAvatar: 'modal-click-avatar',
    ModalResubscribeHint: 'modal-resubscribe-hint',
    MyProfileLivestreamSessionLeaderboard:
      'my-profile-livestream-session-leaderboard',
    DisplayNameNotSaved: 'unsaved-changes',
    ActivateWebAuthn: 'modal-activate-webauthn',
    DeactivateWebAuthn: 'modal-deactivate-webauthn',
  },
  Referral: {
    ReferralBonusDuration: 'referral-bonus-duration',
  },
};

export const processMixpanelApiCall = ({ apiNames, args }) => {
  const log = mixpanelLog.extend('processMixpanelApiCall');
  log('init', 'apiNames:', apiNames, ', args:', args);
  if (!window.mixpanel) {
    throw new Error('missing mixpanel instance');
  }

  let tempApi = window.mixpanel;
  let _this = window.mixpanel;
  apiNames.forEach(name => {
    _this = tempApi;
    tempApi = tempApi?.[name];
  });

  const result = tempApi?.apply?.(_this, args);
  log('done');
  return result;
};

export const getViewId = getMixpanelViewId;

export const getDataFromPath = getMixpanelViewIdAndPathParameter;

/**
 * @typedef {Object} EventTypes - map event name to mixpanel
 * @property {string} PLAYER_VISIBLE
 * @property {string} PLAYER_HIDDEN
 * @property {string} PLAYER_STARTED
 * @property {string} PLAYER_STOPPED
 * @property {string} USER_CLICKED
 * @property {string} VIEW_OPENED
 * @property {string} VIEW_CLOSED
 * @property {string} HAPPYHOUR_NAVBAR_CLICKED
 * @property {string} HAPPYHOUR_CONTENT_PAGE_GREEN_BANNER_CLICKED
 * @property {string} HAPPYHOUR_CONTENT_PAGE_RED_BANNER_CLICKED
 * @property {string} HAPPYHOUR_CONTENT_PAGE_LOGO_CLICKED
 * @property {string} HAPPYHOUR_OVERLAY_INFO_CLICKED
 * @property {string} HAPPYHOUR_OVERLAY_SHAREBUTTON
 * @property {string} HAPPYHOUR_PAGE_OPENED
 */

export const EventTypes = {
  PLAYER_VISIBLE: 'player.visible',
  PLAYER_HIDDEN: 'player.hidden',
  PLAYER_STARTED: 'player.started',
  PLAYER_STOPPED: 'player.stopped',
  USER_CLICKED: 'v2.user.clicked',
  VIEW_OPENED: 'v2.view.opened',
  VIEW_CLOSED: 'v2.view.closed',
  HAPPYHOUR_NAVBAR_CLICKED: 'happyhour.navbar.clicked',
  HAPPYHOUR_CONTENT_PAGE_GREEN_BANNER_CLICKED:
    'happyhour.content.page.green.banner.clicked',
  HAPPYHOUR_CONTENT_PAGE_RED_BANNER_CLICKED:
    'happyhour.content.page.red.banner.clicked',
  HAPPYHOUR_CONTENT_PAGE_LOGO_CLICKED: 'happyhour.content.page.logo.clicked',
  HAPPYHOUR_OVERLAY_INFO_CLICKED: 'happyhour.overlay.info.clicked',
  HAPPYHOUR_OVERLAY_SHAREBUTTON: 'happyhour.overlay.sharebutton',
  HAPPYHOUR_PAGE_OPENED: 'happyhour.page.opened',
  NOTIFICATION_DISPLAYED: 'notification.displayed',
  NOTIFICATION_CLICKED: 'notification.clicked',
  USER_FLIX_NO_SEEK: 'user.flix-no-seek',
  USER_FLIX_REGULAR_SEEK: 'user.flix-regular-seek',
  USER_SESSION_TICK: 'user.session-tick',
  USER_WATERMARK_STYLE_CHANGED: 'user.watermark-style-changed',
  USER_WATERMARK_VALIDATE: 'user.watermark-validate',
  USER_WATERMARK_INTERSECION_VALIDATE: 'user.watermark-intersection-validate',
  USER_VIDEO_LOCATION_CHANGED: 'user.video-location-changed',
  USER_VIDEO_SET_CONTROLS: 'user.video-set-controls',
  USER_VIDEO_ENTER_PIP: 'user.video-enter-pip',
  USER_NON_PLAYER_ENTER_FULLSCREEN: 'user.non-player-enter-fullscreen',
  USER_FLIX_DISTURBED: 'user.flix-disturbed',
  REVENUE_KEEPER: 'user.trigger-revenue-keeper',
  USER_FLIX_GOT_HELP: 'user.flix-got-help',
  USER_TRY_OVERRIDE_WINDOW_FUNCTION: 'user.try-override-window-function',
  USER_FLIX_TRY_MODIFY_Z_INDEX: 'user.flix-try-modify-z-index',
  USER_FLIX_SEEK_BEHAVIOR: 'user.flix-seek-behavior',
  USER_BACKGROUND_ENHANCED: 'user.background-enhanced',
  LIVESTREAM_PAYMENT: 'livestream.payment',
  LIVESTREAM_PAYMENT_CANCELED: 'livestream.payment.canceled',
  LIVESTREAM_TOKEN_GOT: 'livestream.token-got',
  LIVESTREAM_STREAM_AUTHORIZED: 'livestream.stream-authorized',
  LIVESTREAM_PLAYER_FALLBACK: 'player.fallback',
  LIVESTREAM_PLAYER_BUFFER_STALLED: 'player.buffer-stalled',
  LIVESTREAM_PLAYER_WAITING: 'player.waiting',
  PUSHER_EVENT_GOT: 'pusher.event-got',
  PUSHER_CHANNEL_STALLED: 'pusher.channel-stalled',
  USER_DEBUG_SENT: 'user.debug.sent',
  USER_LOGGED_IN: 'user.logged_in',
};

const AVAILABILITY_TRACK_KEY = '_shouldTrackMixpanelUnavailable';

/** @type {boolean | null} */
let shouldTrackUnavailable = null;

export const getShouldTrackUnavailable = async () => {
  if (shouldTrackUnavailable === null) {
    const { getItem } = await import('../resource/persist.js');
    shouldTrackUnavailable = await getItem(AVAILABILITY_TRACK_KEY);
  }
  return shouldTrackUnavailable;
};

/**
 * Begin to track mixpanel availability, would update result of getShouldTrackUnavailable if fails too many times in sequence
 */
export const trackAvailability = () => {
  if (typeof window === 'undefined') return;
  const _ping = (url, timeout) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.timeout = timeout;
      xhr.onload = () => resolve(true);
      xhr.ontimeout = () => reject(new Error('request timeout'));
      xhr.onerror = () => reject(new Error(xhr.status));
      xhr.open('POST', url, true);
      xhr.send();
    });
  };

  let failedTimesInSequence = 0;
  let pingTimeoutHandle = null;

  const ping = async () => {
    let isAvailable = false;
    try {
      const url = new URL('track/', 'https://api-js.mixpanel.com');
      url.searchParams.append('ip', '1');
      url.searchParams.append('_', new Date().getTime().toString());
      isAvailable = await _ping(url.href, 5000);
    } catch (err) {
      // no-op
    } finally {
      const { setItem, removeItem } = await import('../resource/persist.js');
      if (isAvailable) {
        // reset failed times and ping again later
        setTimeout(ping, 30000); // TODO: remote config
        failedTimesInSequence = 0;
        // Unmark
        if (await getShouldTrackUnavailable()) {
          shouldTrackUnavailable = false;
          await removeItem(AVAILABILITY_TRACK_KEY);
        }
      } else {
        failedTimesInSequence++;
        if (failedTimesInSequence < 3) {
          // Retry later
          pingTimeoutHandle = setTimeout(ping, 2000); // TODO: remote config
        } else {
          // Mark as unavailable
          clearTimeout(pingTimeoutHandle);
          shouldTrackUnavailable = true;
          await setItem(AVAILABILITY_TRACK_KEY, true);
        }
      }
    }
  };
  pingTimeoutHandle = setTimeout(ping, 5000); // TODO: remote config
};

/**
 * Set Name on user's Mixpanel profile
 * @param {Object} arg
 * @param {Object} arg.name
 */
export const setName = async ({ name }) => {
  return processMixpanelApiCall({
    apiNames: ['people', 'set'],
    args: [{ $name: name }],
  });
};

/**
 * Set Email on user's Mixpanel profile
 * @param {Object} arg
 * @param {Object} arg.email
 */
export const setEmail = async ({ email }) => {
  return processMixpanelApiCall({
    apiNames: ['people', 'set'],
    args: [{ $email: email }],
  });
};

/**
 * Set utm on Mixpanel and remove unused properties
 * @param {Object} arg
 * @param {Object} arg.utm
 */
export const updateMixpanelUtm = async ({ utm }) => {
  setPrependData({ utm });

  // Unregister unused utm properties
  const utmKeys = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_term',
  ];
  utmKeys.forEach(key => {
    // Need to unregister UTM super property
    // to prevent mixpanel MERGE origin/new utm value from URL
    if (!utm[key]) {
      processMixpanelApiCall({ apiNames: ['unregister'], args: [key] });
    }
  });
};

/**
 * The metadata of tracking event
 * @typedef {Object} EventMeta
 * @property {string} browser
 * @property {string} flavor
 * @property {string} language
 * @property {string} os
 * @property {string} platform
 * @property {string} version
 * @property {string} app
 */

/**
 * The UTM data
 * @typedef {Object} UtmData
 * @property {string} utm_source
 * @property {string} utm_medium
 * @property {string} utm_campaign
 */

/**
 * The payload of tracking event
 * @typedef {Object} EventPayload
 * @property {EventMeta} metadata
 * @property {string} viewId
 * @property {string} modalId
 * @property {string} messageId
 * @property {any} abData
 * @property {Object} [extraProps]
 */

/**
 * The event object for tracking
 * @typedef {Object} TrackedEvent
 * @property {string} type - the type of event, also as event name for mixpanel
 * @property {EventPayload} payload - the data of the event
 */

const refineVersionValue = () => {
  const version = env.TAG_NAME || env.BRANCH_NAME || 'local';
  if (version.indexOf('v') === 0) {
    return version.slice(1);
  }

  return version;
};

/**
 * Get language metadata.
 * @returns {object} return object with 'metadata.language' property and the value is converted i18n.language.
 */
const getLanguageMetadata = () => {
  if (i18n?.language) {
    let language = i18n.language.toLowerCase();

    if (language === 'zh-cn') {
      language = 'zh';
    } else if (language === 'zh-tw') {
      language = 'zh-hant';
    } else {
      language = language?.replace(/-[A-Z]+/i, '');
    }

    return {
      'metadata.language': language,
    };
  }

  return {};
};

const defaultMeta = {
  'metadata.browser': 'web',
  ...getLanguageMetadata(),
  'metadata.os': getGenericOSName(),
  'metadata.platform': getDeviceType() || 'desktop',
  'metadata.version': refineVersionValue(),
  'metadata.user_agent': getUserAgent()?.ua,
};

let remoteMeta = {};

/**
 * Set the meta to the local variable
 * @param {Object} arg
 * @param {Object} arg.data - metadata from pusher remote config
 */
export const setRemoteMeta = ({ data }) => {
  remoteMeta = Object.assign({}, remoteMeta, data, getLanguageMetadata());
};

let prependData = {
  utm: {},
};

let fpjsData = {};

/**
 * Prepend fpjs data properties to subsequent events
 * @param {object} data
 */
export const setFpJsData = async data => {
  if (data.visitorId) {
    processMixpanelApiCall({
      apiNames: ['people', 'union'],
      args: ['fpId', data.visitorId],
    });
  }
  Object.keys(data).forEach(key => {
    fpjsData[`fpjs.${key}`] = data[key];
  });
};

/**
 * Set the utm
 * @param {Object} arg
 * @param {Object} arg.utm
 */
export const setPrependData = ({ utm = {} }) => {
  let data = {};

  if (typeof utm === 'object' && Object.keys(utm).length > 0) {
    data.utm = utm;
  }

  prependData = Object.assign({}, prependData, data);
};

const AFFILIATE_PREFIX_FIELD = [
  'affise',
  'ao',
  'c_track',
  'c_aff',
  'c_off',
  'voluum',
];

/**
 *
 * @param {EventPayload} payload
 * @return {EventPayload}
 */
const refinePayload = payload => {
  /** @type {UtmData} */
  const utm = prependData.utm
    ? Object.keys(prependData.utm).reduce((acc, key) => {
        if (key.startsWith('utm_')) {
          return { ...acc, [key]: prependData.utm[key] };
        }

        let affiliateKey = undefined;
        AFFILIATE_PREFIX_FIELD.forEach(affiliate => {
          if (key.startsWith(affiliate) && prependData.utm[key]) {
            affiliateKey = `affiliate.${key.replace(
              `${affiliate}_`,
              `${affiliate}.`
            )}`;
          }
        });
        if (affiliateKey)
          return { ...acc, [affiliateKey]: prependData.utm[key] };

        return acc;
      }, {})
    : {};

  /** refine the meta with prefix `metadata.` */
  const meta = payload.metadata
    ? Object.keys(payload.metadata).reduce(
        (acc, key) =>
          payload.metadata[key] != null
            ? { ...acc, [`metadata.${key}`]: payload.metadata[key] }
            : acc,
        {}
      )
    : {};
  delete payload.metadata;

  /** refine the ab testing data from payload */
  const ab = payload.abData
    ? Object.keys(payload.abData).reduce(
        (acc, key) => ({ ...acc, [`ab.${key}`]: payload.abData[key] }),
        {}
      )
    : {};
  delete payload.abData;

  const data = Object.assign(
    {},
    defaultMeta,
    payload,
    meta,
    remoteMeta,
    utm,
    ab,
    fpjsData
  );

  return Object.keys(data).reduce(
    (acc, key) => (data[key] != null ? { ...acc, [key]: data[key] } : acc),
    {}
  );
};

/**
 * Wrap the mixpanel track method
 * @param {TrackedEvent} event - the event object
 */
const track = async ({ type, payload }) => {
  const timestamp = Date.now();
  const refinedData = refinePayload(payload);

  if (payload?.shouldSendBeacon) {
    return processMixpanelApiCall({
      apiNames: ['track'],
      args: [
        type,
        { time: timestamp, ...refinedData },
        { transport: 'sendBeacon' },
      ],
    });
  } else {
    return processMixpanelApiCall({
      apiNames: ['track'],
      args: [type, { time: timestamp, ...refinedData }],
    });
  }
};

/**
 * Handle the event by the event type
 * @param {TrackedEvent} event - the event object
 */
const eventReducer = async ({ type, payload }) => {
  if (!payload?.shouldSendBeacon) {
    await new Promise(window.requestIdleCallback);
  }
  switch (type) {
    case EventTypes.PLAYER_VISIBLE:
    case EventTypes.PLAYER_STARTED:
    case EventTypes.PLAYER_STOPPED:
    case EventTypes.PLAYER_HIDDEN:
      return _playerTracked(type, payload);
    case EventTypes.USER_CLICKED:
      return _userClicked(payload);
    case EventTypes.VIEW_OPENED:
      return _viewOpened(payload);
    case EventTypes.VIEW_CLOSED:
      return _viewClosed(payload);
    case EventTypes.HAPPYHOUR_NAVBAR_CLICKED:
      return _happyhourNavbarClicked(payload);
    case EventTypes.HAPPYHOUR_CONTENT_PAGE_GREEN_BANNER_CLICKED:
      return _happyhourContentPageGreenBannerClicked(payload);
    case EventTypes.HAPPYHOUR_CONTENT_PAGE_RED_BANNER_CLICKED:
      return _happyhourContentPageRedBannerClicked(payload);
    case EventTypes.HAPPYHOUR_CONTENT_PAGE_LOGO_CLICKED:
      return _happyhourContentPageLogoClicked(payload);
    case EventTypes.HAPPYHOUR_OVERLAY_INFO_CLICKED:
      return _happyhourOverlayInfoClicked(payload);
    case EventTypes.HAPPYHOUR_OVERLAY_SHAREBUTTON:
      return _happyhourOverlaySharebutton(payload);
    case EventTypes.HAPPYHOUR_PAGE_OPENED:
      return _happyhourPageOpened(payload);
    case EventTypes.CHAT_TEMPLATE_OPENED:
      return _chatTemplateOpened(payload);
    case EventTypes.NOTIFICATION_DISPLAYED:
      return _notificationDisplayed(payload);
    case EventTypes.NOTIFICATION_CLICKED:
      return _notificationClicked(payload);
    case EventTypes.USER_FLIX_NO_SEEK:
      return _userFlixNoSeek(payload);
    case EventTypes.USER_FLIX_REGULAR_SEEK:
      return _userFlixRegularSeek(payload);
    case EventTypes.USER_SESSION_TICK:
      return _userSessionTick(payload);
    case EventTypes.USER_WATERMARK_STYLE_CHANGED:
      return _userWatermarkStyleChanged(payload);
    case EventTypes.USER_WATERMARK_VALIDATE:
      return _userWatermarkValidate(payload);
    case EventTypes.USER_WATERMARK_INTERSECION_VALIDATE:
      return _userWatermarkIntersectionValidate(payload);
    case EventTypes.USER_VIDEO_LOCATION_CHANGED:
      return _userVideoLocationChanged(payload);
    case EventTypes.USER_VIDEO_SET_CONTROLS:
      return _userVideoSetControls(payload);
    case EventTypes.USER_VIDEO_ENTER_PIP:
      return _userVideoEnterPip(payload);
    case EventTypes.USER_NON_PLAYER_ENTER_FULLSCREEN:
      return _userNonPlayerEnterFullscreen(payload);
    case EventTypes.USER_FLIX_DISTURBED:
      return _userFlixDisturbed(payload);
    case EventTypes.REVENUE_KEEPER:
      return _revenueKeeper(payload);
    case EventTypes.USER_FLIX_GOT_HELP:
      return _userFlixGotHelp(payload);
    case EventTypes.USER_TRY_OVERRIDE_WINDOW_FUNCTION:
      return _userTryOverrideWindowFunction(payload);
    case EventTypes.USER_FLIX_TRY_MODIFY_Z_INDEX:
      return _userFlixTryModifyZIndex(payload);
    case EventTypes.USER_FLIX_SEEK_BEHAVIOR:
      return _userFlixSeekBehavior(payload);
    case EventTypes.USER_BACKGROUND_ENHANCED:
      return _userBackgroundEnhanced(payload);
    case EventTypes.LIVESTREAM_PAYMENT:
      return _liveStreamPayment(payload);
    case EventTypes.LIVESTREAM_PAYMENT_CANCELED:
      return _liveStreamPaymentCancel(payload);
    case EventTypes.LIVESTREAM_STREAM_AUTHORIZED:
      return _liveStreamStreamAuthorized(payload);
    case EventTypes.LIVESTREAM_TOKEN_GOT:
      return _liveStreamTokenGot(payload);
    case EventTypes.LIVESTREAM_PLAYER_FALLBACK:
      return _liveStreamPlayerFallback(payload);
    case EventTypes.LIVESTREAM_PLAYER_BUFFER_STALLED:
      return _liveStreamPlayerBufferStalled(payload);
    case EventTypes.LIVESTREAM_PLAYER_WAITING:
      return _liveStreamPlayerPlayerWaiting(payload);
    case EventTypes.PUSHER_EVENT_GOT:
      return _pusherEventGot(payload);
    case EventTypes.USER_DEBUG_SENT:
      return _userDebugSent(payload);
    case EventTypes.USER_LOGGED_IN:
      return _userLoggedIn(payload);
    case EventTypes.PUSHER_CHANNEL_STALLED:
      return _pusherChannelStalled(payload);
  }
};

export default eventReducer;

/**
 * Handle the following events:
 * PLAYER_VISIBLE, PLAYER_STARTED, PLAYER_STOPPED, PLAYER_HIDDEN
 * @param {string} type
 * @param {EventPayload} payload
 * @return {void}
 */
const _playerTracked = (
  type,
  {
    metadata,
    viewId,
    modalId,
    messageId,
    streamId,
    abData,
    shouldSendBeacon,
    extraProps = {},
  } = {}
) => {
  const data = {
    metadata,
    'modal.id': modalId,
    'view.id': viewId,
    'message.id': messageId,
    'stream.id': streamId,
    abData,
    shouldSendBeacon,
    ...extraProps,
  };

  track({ type, payload: data });
};

/**
 * Handle the EventTypes.USER_CLICKED event
 * @param {EventPayload} payload
 * @return {void}
 */
const _userClicked = ({
  metadata,
  viewId,
  buttonId,
  messageId,
  modalId,
  abData,
  extraProps = {},
} = {}) => {
  const data = {
    metadata,
    'modal.id': modalId,
    'view.id': viewId,
    'button.id': buttonId,
    'message.id': messageId,
    abData,
    ...extraProps,
  };

  track({ type: EventTypes.USER_CLICKED, payload: data });
};

/**
 * Handle the EventTypes.VIEW_OPENED event
 * @param {EventPayload} payload
 * @return {void}
 */
const _viewOpened = ({
  metadata,
  viewId,
  modalId,
  messageId,
  abData,
  extraProps = {},
} = {}) => {
  const data = {
    metadata,
    'view.id': viewId,
    'modal.id': modalId,
    'message.id': messageId,
    abData,
    ...extraProps,
  };

  track({ type: EventTypes.VIEW_OPENED, payload: data });
};

/**
 * Handle the EventTypes.VIEW_CLOSED event
 * @param {EventPayload} payload
 * @return {void}
 */
const _viewClosed = ({
  metadata,
  viewId,
  modalId,
  messageId,
  abData,
  extraProps = {},
} = {}) => {
  const data = {
    metadata,
    'view.id': viewId,
    'modal.id': modalId,
    'message.id': messageId,
    abData,
    ...extraProps,
  };

  track({ type: EventTypes.VIEW_CLOSED, payload: data });
};

/**
 * Handle HAPPYHOUR_NAVBAR_CLICKED event.
 * @kind mixpanel/eventType
 * @name _happyhourNavbarClicked
 * @param {string} {userId} - userId
 * @param {string} {platform} - desktop | iOS | Android
 */
const _happyhourNavbarClicked = ({ userId, platform }) => {
  track({
    type: EventTypes.HAPPYHOUR_NAVBAR_CLICKED,
    payload: { userId, platform },
  });
};

/**
 * Handle HAPPYHOUR_CONTENT_PAGE_GREEN_BANNER_CLICKED event.
 * @kind mixpanel/eventType
 * @name _happyhourContentPageGreenBannerClicked
 * @param {string} {userId} - userId
 * @param {string} {platform} - desktop | iOS | Android
 */
const _happyhourContentPageGreenBannerClicked = ({ userId, platform }) => {
  track({
    type: EventTypes.HAPPYHOUR_CONTENT_PAGE_GREEN_BANNER_CLICKED,
    payload: {
      userId,
      platform,
    },
  });
};

/**
 * Handle HAPPYHOUR_CONTENT_PAGE_RED_BANNER_CLICKED event.
 * @kind mixpanel/eventType
 * @name _happyhourContentPageRedBannerClicked
 * @param {string} {userId} - userId
 * @param {string} {platform} - desktop | iOS | Android
 */
const _happyhourContentPageRedBannerClicked = ({ userId, platform }) => {
  track({
    type: EventTypes.HAPPYHOUR_CONTENT_PAGE_RED_BANNER_CLICKED,
    payload: {
      userId,
      platform,
    },
  });
};

/**
 * Handle HAPPYHOUR_CONTENT_PAGE_LOGO_CLICKED event.
 * @kind mixpanel/eventType
 * @name _happyhourContentPageLogoClicked
 * @param {string} {userId} - userId
 * @param {string} {platform} - desktop | iOS | Android
 */
const _happyhourContentPageLogoClicked = ({ userId, platform }) => {
  track({
    type: EventTypes.HAPPYHOUR_CONTENT_PAGE_LOGO_CLICKED,
    payload: {
      userId,
      platform,
    },
  });
};

/**
 * Handle HAPPYHOUR_OVERLAY_INFO_CLICKED event.
 * @kind mixpanel/eventType
 * @name _happyhourOverlayInfoClicked
 * @param {string} {userId} - userId
 * @param {string} {platform} - desktop | iOS | Android
 */
const _happyhourOverlayInfoClicked = ({ userId, platform }) => {
  track({
    type: EventTypes.HAPPYHOUR_OVERLAY_INFO_CLICKED,
    payload: { userId, platform },
  });
};

/**
 * Handle HAPPYHOUR_OVERLAY_SHAREBUTTON event.
 * @kind mixpanel/eventType
 * @name _happyhourOverlaySharebutton
 * @param {string} {userId} - userId
 * @param {string} {platform} - desktop | iOS | Android
 * @param {number} {state} - 0: after event start, 1: event inactive, -1: before event start
 */
const _happyhourOverlaySharebutton = ({ userId, platform, state }) => {
  track({
    type: EventTypes.HAPPYHOUR_OVERLAY_SHAREBUTTON,
    payload: {
      userId,
      platform,
      state,
    },
  });
};

/**
 * Handle HAPPYHOUR_PAGE_OPENED event.
 * @kind mixpanel/eventType
 * @name _happyhourPageOpened
 * @param {string} {userId} - userId
 * @param {string} {platform} - desktop | iOS | Android
 */
const _happyhourPageOpened = ({ userId, platform }) => {
  track({
    type: EventTypes.HAPPYHOUR_PAGE_OPENED,
    payload: {
      userId,
      platform,
    },
  });
};

/**
 * Handle CHAT_TEMPLATE_OPENED event.
 * @kind mixpanel/eventType
 * @name _chatTemplateOpened
 * @param {string} {userUsername} - user username.
 */
const _chatTemplateOpened = ({ userUsername }) => {
  track({
    type: EventTypes.CHAT_TEMPLATE_OPENED,
    payload: {
      'user.username': userUsername,
    },
  });
};

/**
 * Handle NOTIFICATION_DISPLAYED event.
 * @kind mixpanel/eventType
 * @name _notificationDisplayed
 * @param {String} {id} - notification id.
 * @param {String} {type} - notification embedding data type.
 * @param {String} {position} - display position.
 * @param {String} {link} - notification link.
 * @param {number} {exp} - notification exp.
 * @param {String} {viewId} - current path viewId.
 */
const _notificationDisplayed = ({ id, type, position, link, exp, viewId }) => {
  track({
    type: EventTypes.NOTIFICATION_DISPLAYED,
    payload: {
      'notification.id': id,
      'notification.type': type,
      'notification.position': position,
      'notification.link': link,
      'notification.exp': exp,
      'view.id': viewId,
    },
  });
};

/**
 * Handle NOTIFICATION_CLICKED event.
 * @kind mixpanel/eventType
 * @name _notificationClicked
 * @param {String} {id} - notification id.
 * @param {String} {type} - notification embedding data type.
 * @param {String} {position} - display position.
 * @param {String} {link} - notification link.
 * @param {String} {viewId} - current path viewId.
 * @param {String} {buttonId} - button id.
 */
const _notificationClicked = ({
  id,
  type,
  position,
  link,
  viewId,
  buttonId,
}) => {
  track({
    type: EventTypes.NOTIFICATION_CLICKED,
    payload: {
      'notification.id': id,
      'notification.type': type,
      'notification.position': position,
      'notification.link': link,
      'view.id': viewId,
      'button.id': buttonId,
    },
  });
};

/**
 * Handle USER_FLIX_NO_SEEK event.
 * @kind mixpanel/eventType
 * @name _userFlixNoSeek
 * @param {String} {messageId} - message id.
 * @param {String} {userUsername} - cp username.
 * @param {number} {noSeekDuration} - flix no seek duration.
 * @param {number} {messageDuration} - message duration.
 */
const _userFlixNoSeek = ({
  messageId,
  userUsername,
  noSeekDuration,
  messageDuration,
}) => {
  track({
    type: EventTypes.USER_FLIX_NO_SEEK,
    payload: {
      'message.id': messageId,
      'user.username': userUsername,
      'no-seek.duration': noSeekDuration,
      'message.duration': messageDuration,
    },
  });
};

/**
 * Handle USER_FLIX_REGULAR_SEEK event.
 * @kind mixpanel/eventType
 * @name _userFlixRegularSeek
 * @param {String} {messageId} - message id.
 * @param {String} {userUsername} - cp username.
 * @param {number} {regularSeekDuration} - flix regular seek duration.
 * @param {number} {messageDuration} - message duration.
 */
const _userFlixRegularSeek = ({
  messageId,
  userUsername,
  regularSeekDuration,
  messageDuration,
}) => {
  track({
    type: EventTypes.USER_FLIX_REGULAR_SEEK,
    payload: {
      'message.id': messageId,
      'user.username': userUsername,
      'regular.duration': regularSeekDuration,
      'message.duration': messageDuration,
    },
  });
};

/**
 * Handle USER_SESSION_TICK event.
 * @kind mixpanel/eventType
 * @name _userSessionTick
 * @param {String} {viewId} - view id.
 * @param {Number} {sessionDuration} - session duration.
 * @param {Number} {windowInnerWidth} - window inner width.
 * @param {Number} {windowInnerHeight} - window inner height.
 */
const _userSessionTick = ({
  viewId,
  sessionDuration,
  windowInnerWidth,
  windowInnerHeight,
}) => {
  track({
    type: EventTypes.USER_SESSION_TICK,
    payload: {
      'view.id': viewId,
      'session.duration': sessionDuration,
      'window.innerHeight': windowInnerHeight,
      'window.innerWidth': windowInnerWidth,
    },
  });
};

/**
 * Handle USER_WATERMARK_STYLE_CHANGED event.
 * @kind mixpanel/eventType
 * @name _userWatermarkStyleChanged
 * @param {String} {messageId} - message id.
 * @param {String} {userUsername} - cp username.
 * @param {String} {viewId} - view id.
 * @param {array} {changedStyleList} - changed style
 */
const _userWatermarkStyleChanged = ({
  messageId,
  userUsername,
  changedStyleList,
  viewId,
}) => {
  track({
    type: EventTypes.USER_WATERMARK_STYLE_CHANGED,
    payload: {
      'view.id': viewId,
      'message.id': messageId,
      'user.username': userUsername,
      'change.styles': changedStyleList,
    },
  });
};

/**
 * Handle USER_WATERMARK_VALIDATE event.
 * @kind mixpanel/eventType
 * @name _userWatermarkValidate
 * @param {String} {messageId} - message id.
 * @param {String} {userUsername} - cp username.
 * @param {String} {viewId} - view id.
 * @param {number} {filledPixelCount}
 * @param {String} {computedStyleFont}
 * @param {String} {error}
 */
const _userWatermarkValidate = ({
  messageId,
  userUsername,
  viewId,
  filledPixelCount,
  computedStyleFont,
  error,
}) => {
  track({
    type: EventTypes.USER_WATERMARK_VALIDATE,
    payload: {
      'view.id': viewId,
      'message.id': messageId,
      'user.username': userUsername,
      'watermark.computedStyle.font': computedStyleFont,
      'watermark.validation.filledPixelCount': filledPixelCount,
      'watermark.validation.error': error,
    },
  });
};

/**
 * Handle USER_WATERMARK_INTERSECION_VALIDATE event.
 * @kind mixpanel/eventType
 * @name _userWatermarkIntersectionValidate
 * @param {String} {messageId} - message id.
 * @param {String} {userUsername} - cp username.
 * @param {String} {viewId} - view id.
 * @param {object} {report}
 */
const _userWatermarkIntersectionValidate = ({
  messageId,
  userUsername,
  viewId,
  report,
}) => {
  track({
    type: EventTypes.USER_WATERMARK_INTERSECION_VALIDATE,
    payload: {
      'view.id': viewId,
      'message.id': messageId,
      'user.username': userUsername,
      ...Object.keys(report).reduce(
        (acc, key) => ({
          ...acc,
          [`watermark.validation.${key}`]: report[key],
        }),
        {}
      ),
    },
  });
};

/**
 * Handle USER_VIDEO_LOCATION_CHANGED event.
 * @kind mixpanel/eventType
 * @name _userVideoLocationChanged
 * @param {String} {messageId} - message id.
 * @param {String} {userUsername} - cp username.
 * @param {String} {viewId} - view id.
 */
const _userVideoLocationChanged = ({ messageId, userUsername, viewId }) => {
  track({
    type: EventTypes.USER_VIDEO_LOCATION_CHANGED,
    payload: {
      'message.id': messageId,
      'user.username': userUsername,
      'view.id': viewId,
    },
  });
};

/**
 * Handle USER_VIDEO_SET_CONTROLS event.
 * @kind mixpanel/eventType
 * @name _userVideoSetControls
 * @param {String} {messageId} - message id.
 * @param {String} {userUsername} - cp username.
 * @param {String} {viewId} - view id.
 * @param {String} {controlsValue} - value of controls attribute
 */
const _userVideoSetControls = ({
  messageId,
  userUsername,
  viewId,
  controlsValue,
}) => {
  track({
    type: EventTypes.USER_VIDEO_SET_CONTROLS,
    payload: {
      'message.id': messageId,
      'user.username': userUsername,
      'view.id': viewId,
      controlsValue,
    },
  });
};

/**
 * Handle USER_VIDEO_ENTER_PIP event.
 * @kind mixpanel/eventType
 * @name _userVideoEnterPip
 */
const _userVideoEnterPip = () => {
  track({
    type: EventTypes.USER_VIDEO_ENTER_PIP,
    payload: {},
  });
};

/**
 * Handle USER_NON_PLAYER_ENTER_FULLSCREEN event.
 * @kind mixpanel/eventType
 * @name _userEnterUnrecognizedFullscreen
 */
const _userNonPlayerEnterFullscreen = ({ nodeHTML }) => {
  track({
    type: EventTypes.USER_NON_PLAYER_ENTER_FULLSCREEN,
    payload: {
      nodeHTML,
    },
  });
};

/**
 * Handle USER_FLIX_DISTURBED event.
 * @kind mixpanel/eventType
 * @name _userFlixDisturbed
 * @param {String} {messageId} - message id.
 * @param {String} {userUsername} - cp username.
 * @param {number} {messageDuration} - message duration.
 * @param {string} {action} - disturbing action.
 * @param {number} {currentTime} - video current time.
 * @param {number} {shuffledTime} - video shuffled time.
 * @param {number} {shuffledPlaybackRate} - flix shuffled playback rate.
 */
const _userFlixDisturbed = ({
  messageId,
  userUsername,
  messageDuration,
  action,
  currentTime,
  shuffledTime,
  shuffledPlaybackRate,
}) => {
  track({
    type: EventTypes.USER_FLIX_DISTURBED,
    payload: {
      'message.id': messageId,
      'user.username': userUsername,
      'message.duration': messageDuration,
      'disturb.action': action,
      'disturb.currentTime': currentTime,
      'disturb.shuffledTime': shuffledTime,
      'disturb.shuffledPlaybackRate': shuffledPlaybackRate,
    },
  });
};

/**
 * Handle REVENUE_KEEPER event.
 * @kind mixpanel/eventType
 * @name _revenueKeeper
 */
const _revenueKeeper = () => {
  track({
    type: EventTypes.REVENUE_KEEPER,
    payload: {},
  });
};

/**
 * Handle USER_FLIX_GOT_HELP event.
 * @kind mixpanel/eventType
 * @name _userFlixGotHelp
 * @param {'set'|'execute'} action
 */
const _userFlixGotHelp = ({ action }) => {
  track({
    type: EventTypes.USER_FLIX_GOT_HELP,
    payload: {
      action,
    },
  });
};

/**
 * Handle USER_TRY_OVERRIDE_WINDOW_FUNCTION event.
 * @kind mixpanel/eventType
 * @name _userTryOverrideWindowFunction
 * @param {string} {functionName} - name of function to be overridden
 * @param {*} {functionValue} - value of function to override to
 */
const _userTryOverrideWindowFunction = ({ functionName, functionValue }) => {
  track({
    type: EventTypes.USER_TRY_OVERRIDE_WINDOW_FUNCTION,
    payload: {
      functionName,
      functionValue: functionValue.toString(),
    },
  });
};

/**
 * Handle USER_FLIX_TRY_MODIFY_Z_INDEX event.
 * @kind mixpanel/eventType
 * @name _userFlixTryModifyZIndex
 * @param {[string]} {zIndexes} - z-indexes from video to root
 * @param {[string]} {positions} - positions from video to root
 * @param {boolean} {isVideoElementMovedOut} - is video element moved to outside of player's element
 */
const _userFlixTryModifyZIndex = ({
  zIndexes,
  positions,
  isVideoElementMovedOut,
}) => {
  track({
    type: EventTypes.USER_FLIX_TRY_MODIFY_Z_INDEX,
    payload: {
      zIndexes,
      positions,
      isVideoElementMovedOut,
    },
  });
};

/**
 * Handle USER_FLIX_SEEK_BEHAVIOR event.
 * @kind mixpanel/eventType
 * @name _userFlixSeekBehavior
 * @param {[string]} {viewId} - view id.
 * @param {[number]} {startTime} - start seek time.
 * @param {[number]} {endTime} - end seek time.
 * @param {[string]} {messageId} - flix message id.
 * @param {[string]} {messageStatus} - unlock status.
 */
const _userFlixSeekBehavior = ({
  viewId,
  startTime,
  endTime,
  messageStatus,
  messageId,
}) => {
  track({
    type: EventTypes.USER_FLIX_SEEK_BEHAVIOR,
    payload: {
      viewId,
      messageId,
      messageStatus,
      'seek.startTime': startTime,
      'seek.endTime': endTime,
      'seek.duration': endTime - startTime,
      'seek.duration.abs': Math.abs(endTime - startTime),
    },
  });
};

/**
 * Handle USER_BACKGROUND_ENHANCED event.
 * @kind mixpanel/eventType
 * @name _userBackgroundEnhanced
 */
const _userBackgroundEnhanced = () => {
  track({
    type: EventTypes.USER_BACKGROUND_ENHANCED,
    payload: {},
  });
};

/**
 * Handle LIVESTREAM_PAYMENT event.
 * @kind mixpanel/eventType
 * @name _liveStreamPayment
 * @param {string} {streamId} - livestream id.
 * @param {string} {status} - livestream status.
 * @param {['CLICK|AUTO']} {via} - livestream payment via action
 * @param {number} {price} - livestream (tikect) payment price.
 * @param {number} {tokenNbf} - livestream curren token nbf.
 * @param {number} {tokenExp} - livestream curren token exp.
 * @param {string} {playerStatus} - livestream player status.
 * @param {string} {preset} - livestream preset.
 * @param {string} {giftId} - livestream tikect gift id.
 * @param {number} {count} - livestream ticket count.
 * @param {string} {privateShowData.modalId} - private show ticket clicked on which modal
 * @param {['SINGLE|MORE|MVP']} {privateShowData.type} - selected private show gift type
 * @param {string} {privateShowData.privateShowStatus} - livestream private show status.
 * @param {string} {privateShowData.fundingId} - livestream private show funding goal id.
 * @param {string} {pusher.connection} - livestream pusher connection status.
 * @param {object} {pusher.channel} - livestream pusher channel status.
 */
const _liveStreamPayment = ({
  streamId,
  status,
  via,
  price,
  tokenNbf,
  tokenExp,
  playerStatus,
  preset,
  giftId,
  count,
  privateShowData,
  pusher,
}) => {
  track({
    type: EventTypes.LIVESTREAM_PAYMENT,
    payload: {
      'stream.id': streamId,
      'stream.status': status,
      'payment.via': via,
      'payment.price': price,
      'token.current.nbf': tokenNbf,
      'token.current.exp': tokenExp,
      'player.status': playerStatus,
      'player.preset': preset,
      'payment.giftId': giftId,
      'payment.count': count,
      'payment.modalId': privateShowData?.modalId,
      'payment.type': privateShowData?.type,
      'privateShow.status': privateShowData?.privateShowStatus,
      'privateShow.fundingId': privateShowData?.fundingId,
      'pusher.connection': pusher?.connection,
      'pusher.channel': pusher?.channel,
    },
  });
};

/**
 * Handle LIVESTREAM_PAYMENT_CANCELED event.
 * @kind mixpanel/eventType
 * @name _liveStreamPaymentCancel
 * @param {string} {streamId} - livestream id.
 * @param {string} {status} - livestream status.
 * @param {['CLICK|AUTO']} {via} - livestream payment via action
 * @param {number} {price} - livestream (tikect) payment price.
 * @param {number} {tokenNbf} - livestream curren token nbf.
 * @param {number} {tokenExp} - livestream curren token exp.
 * @param {string} {playerStatus} - livestream player status.
 * @param {string} {preset} - livestream preset.
 * @param {string} {giftId} - livestream tikect gift id.
 * @param {number} {count} - livestream ticket count.
 * @param {string} {privateShowData.modalId} - private show ticket clicked on which modal
 * @param {['SINGLE|MORE|MVP']} {privateShowData.type} - selected private show gift type
 * @param {string} {privateShowData.privateShowStatus} - livestream private show status.
 * @param {string} {privateShowData.fundingId} - livestream private show funding goal id.
 */
const _liveStreamPaymentCancel = ({
  streamId,
  status,
  via,
  price,
  tokenNbf,
  tokenExp,
  playerStatus,
  preset,
  giftId,
  count,
  privateShowData,
}) => {
  track({
    type: EventTypes.LIVESTREAM_PAYMENT_CANCELED,
    payload: {
      'stream.id': streamId,
      'stream.status': status,
      'payment.via': via,
      'payment.price': price,
      'token.current.nbf': tokenNbf,
      'token.current.exp': tokenExp,
      'player.status': playerStatus,
      'player.preset': preset,
      'payment.giftId': giftId,
      'payment.count': count,
      'payment.modalId': privateShowData?.modalId,
      'payment.type': privateShowData?.type,
      'privateShow.status': privateShowData?.privateShowStatus,
      'privateShow.fundingId': privateShowData?.fundingId,
    },
  });
};

/**
 * Handle LIVESTREAM_STREAM_AUTHORIZED event.
 * @kind mixpanel/eventType
 * @name _liveStreamTokenGot
 * @param {string} {streamId} - livestream id.
 * @param {string} {status} - livestream status.
 * @param {string} {preset} - livestream player preset.
 * @param {string} {privateShowStatus} - livestream private show status.
 */
const _liveStreamStreamAuthorized = ({
  streamId,
  status,
  preset,
  privateShowStatus,
}) => {
  track({
    type: EventTypes.LIVESTREAM_STREAM_AUTHORIZED,
    payload: {
      'stream.id': streamId,
      'stream.status': status,
      'player.preset': preset,
      'privateShow.status': privateShowStatus,
    },
  });
};

/**
 * Handle LIVESTREAM_TOKEN_GOT event.
 * @kind mixpanel/eventType
 * @name _liveStreamTokenGot
 * @param {string} {streamId} - livestream id.
 * @param {string} {status} - livestream status.
 * @param {number} {tokenNbf} - livestream token nbf.
 * @param {number} {tokenExp} - livestream token exp.
 * @param {string} {playerStatus} - livestream player status.
 * @param {string} {preset} - livestream preset.
 */
const _liveStreamTokenGot = ({
  streamId,
  status,
  tokenNbf,
  tokenExp,
  playerStatus,
  preset,
}) => {
  track({
    type: EventTypes.LIVESTREAM_TOKEN_GOT,
    payload: {
      'stream.id': streamId,
      'stream.status': status,
      'token.nbf': tokenNbf,
      'token.exp': tokenExp,
      'player.status': playerStatus,
      'player.preset': preset,
    },
  });
};
/**
 * Handle LIVESTREAM_PLAYER_FALLBACK event.
 * @kind mixpanel/eventType
 * @name _liveStreamPlayerFallback
 * @param {string} {streamId} - livestream id.
 * @param {string} {status} - livestream status.
 * @param {number} {tokenNbf} - livestream token nbf.
 * @param {number} {tokenExp} - livestream token exp.
 * @param {string} {playerStatus} - livestream player status.
 * @param {string} {preset} - livestream preset.
 * @param {string} {errorMessage} - player error message.
 */
const _liveStreamPlayerFallback = ({
  streamId,
  status,
  tokenNbf,
  tokenExp,
  playerStatus,
  preset,
  errorMessage,
}) => {
  track({
    type: EventTypes.LIVESTREAM_PLAYER_FALLBACK,
    payload: {
      'stream.id': streamId,
      'stream.status': status,
      'token.nbf': tokenNbf,
      'token.exp': tokenExp,
      'player.status': playerStatus,
      'player.preset': preset,
      'error.message': errorMessage,
    },
  });
};

/**
 * Handle LIVESTREAM_PLAYER_BUFFER_STALLED event.
 * @kind mixpanel/eventType
 * @name _liveStreamPlayerBufferStalled
 * @param {string} {streamId} - livestream id.
 * @param {string} {sessionId} - session id
 * @param {string} {preset} - livestream preset
 * @param {string} {source} - player source
 * @param {string} {streamStatus} - livestream status
 * @param {string} {mediaType} - Buffer stalled media type
 * @param {object} {avgBufferLength} - livestream average buffer length
 * @param {[number]} {bufferHistory} - complete buffer history
 * @param {object} {avgThroughput} - livestream average throughput
 * @param {[number]} {throughputHistory} - Throughput history
 * @param {[object]} {ManifestRepeatHistory} - repeat manifest history
 * @param {number} {tokenNbf} - stream token nbf
 * @param {number} {tokenExp} - stream token exp
 * @param {boolean} {enabledLowLatency} - low latency enabled
 * @param {string} {fundingId} - funding id
 * @param {string} {performingId} - performing id
 */
const _liveStreamPlayerBufferStalled = ({
  streamId,
  sessionId,
  streamStatus,
  preset,
  source,
  mediaType,
  avgBufferLength,
  bufferHistory,
  avgThroughput,
  throughputHistory,
  manifestRepeatHistory,
  tokenNbf,
  tokenExp,
  enabledLowLatency,
  fundingId,
  performingId,
}) => {
  track({
    type: EventTypes.LIVESTREAM_PLAYER_BUFFER_STALLED,
    payload: {
      'stream.id': streamId,
      'stream.sessionId': sessionId,
      'stream.stauts': streamStatus,
      'player.preset': preset,
      'player.source': source,
      'stream.lowLatency.enabled': enabledLowLatency,
      'stream.event.mediaType': mediaType,
      'stream.avgBufferLength': avgBufferLength,
      'stream.bufferHistory': bufferHistory,
      'stream.avgThroughput': avgThroughput,
      'stream.throughputHistory': throughputHistory,
      'stream.manifestRepeatHistory': manifestRepeatHistory,
      'token.current.nbf': tokenNbf,
      'token.current.exp': tokenExp,
      'privateShow.fundingId': fundingId,
      'privateShow.performingId': performingId,
    },
  });
};

/**
 * Handle LIVESTREAM_PLAYER_WAITING event.
 * @kind mixpanel/eventType
 * @name _liveStreamPlayerPlayerWaiting
 * @param {string} {streamId} - livestream id.
 * @param {string} {source} - player source
 * @param {string} {sessionId} - session id
 * @param {string} {preset} - livestream preset
 * @param {string} {streamStatus} - livestream status
 * @param {number} {duration} - duration of waiting
 * @param {object} {avgBufferLength} - livestream average buffer length
 * @param {[number]} {bufferHistory} - complete buffer history
 * @param {object} {avgThroughput} - livestream average throughput
 * @param {[number]} {throughputHistory} - Throughput history
 * @param {[object]} {ManifestRepeatHistory} - repeat manifest history
 * @param {number} {tokenNbf} - stream token nbf
 * @param {number} {tokenExp} - stream token exp
 * @param {boolean} {enabledLowLatency} - low latency enabled
 * @param {string} {fundingId} - funding id
 * @param {string} {performingId} - performing id
 */
const _liveStreamPlayerPlayerWaiting = ({
  streamId,
  sessionId,
  streamStatus,
  preset,
  source,
  duration,
  avgBufferLength,
  bufferHistory,
  avgThroughput,
  throughputHistory,
  manifestRepeatHistory,
  tokenNbf,
  tokenExp,
  enabledLowLatency,
  fundingId,
  performingId,
}) => {
  track({
    type: EventTypes.LIVESTREAM_PLAYER_WAITING,
    payload: {
      'stream.id': streamId,
      'stream.sessionId': sessionId,
      'stream.stauts': streamStatus,
      'player.preset': preset,
      'player.source': source,
      'stream.lowLatency.enabled': enabledLowLatency,
      duration: duration,
      'stream.avgBufferLength': avgBufferLength,
      'stream.bufferHistory': bufferHistory,
      'stream.avgThroughput': avgThroughput,
      'stream.throughputHistory': throughputHistory,
      'stream.manifestRepeatHistory': manifestRepeatHistory,
      'token.current.nbf': tokenNbf,
      'token.current.exp': tokenExp,
      'privateShow.fundingId': fundingId,
      'privateShow.performingId': performingId,
    },
  });
};

/**
 * Handle PUSHER_EVENT_GOT event.
 * @kind mixpanel/eventType
 * @name _pusherEventGot
 * @param {[string]} {eventType} - pusher event type
 * @param {[object]} {eventPayload} - pusher event payload
 */
const _pusherEventGot = ({ eventType, eventPayload }) => {
  return track({
    type: EventTypes.PUSHER_EVENT_GOT,
    payload: {
      'event.type': eventType,
      'event.payload': eventPayload,
    },
  });
};

/**
 * Handle PUSHER_CHANNEL_STALLED event.
 * @kind mixpanel/eventType
 * @name _pusherChannelStalled
 * @param {string} {channelName} - channel name
 * @param {boolean} {subscribed} - channel subscribed
 * @param {boolean} {subscriptionCanceled} - channel subscription canceled
 * @param {boolean} {subscriptionPending} - channel subscription pending
 * @param {string} {connectionState} - pusher connection state
 */
const _pusherChannelStalled = ({
  channelName,
  subscribed,
  subscriptionCancelled,
  subscriptionPending,
  connectionState,
}) => {
  return track({
    type: EventTypes.PUSHER_CHANNEL_STALLED,
    payload: {
      'channel.name': channelName,
      'channel.subscribed': subscribed,
      'channel.subscriptionCancelled': subscriptionCancelled,
      'channel.subscriptionPending': subscriptionPending,
      'connection.state': connectionState,
    },
  });
};

/**
 * Handle USER_DEBUG_SENT event.
 * @kind mixpanel/eventType
 * @name _userDebugSent
 * @param {object} {[drmTestResult]} - drm test result.
 * @param {string} {[drmTestMessageId]} - drm test message id.
 * @param {object} {speedtestResult} - speed test result.
 * @param {object} {pusherStatsResult} - pusher connection result.
 */
const _userDebugSent = ({
  drmTestResult = {},
  drmTestMessageId,
  speedtestResult = {},
  pusherStatsResult = {},
}) => {
  const payload = {
    'drm.message.id': drmTestMessageId,
  };
  Object.keys(DrmRobustnessPriority).forEach(keySystem => {
    const regexp = /[^a-zA-Z0-9_]/g;
    const replacedKeySystem = keySystem.replace(regexp, '_').toLowerCase();
    Object.keys(DrmRobustnessPriority[keySystem]).forEach(robustness => {
      const replacedRobustness = robustness.replace(regexp, '_').toLowerCase();
      payload[`drm.${replacedKeySystem}.${replacedRobustness}`] =
        drmTestResult?.[keySystem]?.[robustness] || 'untest';
    });
  });
  Object.keys(speedtestResult).forEach(key => {
    // format to underscore case
    const formattedKey = key
      .split(/(?=[A-Z])/)
      .join('_')
      .toLowerCase();
    payload[`speedtest.${formattedKey}`] = speedtestResult[key];
  });
  Object.keys(pusherStatsResult).forEach(key => {
    // format to underscore case
    const formattedKey = key
      .split(/(?=[A-Z])/)
      .join('_')
      .toLowerCase();
    payload[`pusher.stats.${formattedKey}`] = pusherStatsResult[key];
  });

  return track({
    type: EventTypes.USER_DEBUG_SENT,
    payload,
  });
};

/**
 * Handle USER_LOGGED_IN event.
 * @kind mixpanel/eventType
 * @name _userLoggedIn
 * @param {string} {via} - user login via
 */
const _userLoggedIn = ({ via }) => {
  return track({
    type: EventTypes.USER_LOGGED_IN,
    payload: {
      via,
    },
  });
};

export const mixpanelStartBatchSenders = () => {
  mixpanelLog.extend('mixpanelStartBatchSenders')('start batch senders');
  return window.mixpanel?.start_batch_senders();
};

export const initMixpanel = async ({
  pubSubTopic,
  initEvent = 'load',
} = {}) => {
  const log = mixpanelLog.extend('initMixpanel');
  log('init');

  if (!window.mixpanel) {
    if (!pubSubController) {
      const PubSubController = (await import('../resource/PubSubController.js'))
        .default;
      pubSubController = new PubSubController({ pubSubTopic });
    }

    window.MIXPANEL_CUSTOM_LIB_URL = env.MIXPANEL_CUSTOM_LIB_URL;
    await loadScriptTag({
      id: 'mixpanel-script',
      async: false,
      textContent: MIXPANEL_SCRIPT,
    });

    return new Promise(resolve => {
      const firstEventDelay = +(env.MIXPANEL_FIRST_EVENT_DELAY_MSEC || 10000);
      const flushInterval = +(env.MIXPANEL_BATCH_FLUSH_INTERVAL_MS || 5000);
      window.mixpanel.init(env.MIXPANEL_TOKEN, {
        loaded: () => {
          log('done init mixpanel');
          trackAvailability();

          if (initEvent.includes('load')) {
            setTimeout(() => {
              log(`after ${firstEventDelay}s start_batch_senders`);
              window.mixpanel.start_batch_senders();
            }, firstEventDelay);
          }
          resolve();
        },
        hooks: {
          before_send_events: event => {
            pubSubController.addToQueue(event);
            return event;
          },
          before_send_people: people => {
            pubSubController.addToQueue(people);
            return people;
          },
        },
        api_transport: 'sendBeacon',
        batch_flush_interval_ms: flushInterval,
        // set value to false, and the events won't be sent until invoking start_batch_senders
        batch_autostart: false,
      });
    });
  }
  return window.mixpanel;
};

let uuid;

export const getMixpanelDistinctId = ({ reload = false } = {}) => {
  if (reload) processMixpanelApiCall({ apiNames: ['persistence', 'load'] });
  let distinctId = processMixpanelApiCall({ apiNames: ['get_distinct_id'] });

  if (!uuid && !distinctId) {
    uuid = uuidv4();
    distinctId = uuid;

    processMixpanelApiCall({
      apiNames: ['register'],
      args: [{ distinct_id: uuid }],
    });
  }

  return distinctId;
};

export const setPubSubTopic = async pubSubTopic => {
  if (!pubSubController) {
    const PubSubController = (await import('../resource/PubSubController.js'))
      .default;
    pubSubController = new PubSubController({ pubSubTopic });
  } else pubSubController.setPubSubTopic(pubSubTopic);
};
