// fetchAccessToken.js
'use strict';
// Don't use customFetch, otherwise we'll waiting forever
import 'isomorphic-fetch';

import mergeMeData from '../action/mergeMeData.js';
import getMeData from '../selector/getMeData.js';
import getResourceUrl from '../resource/getResourceUrl.js';
import { getHeaders } from '../resource/fetchOptionHeader.js';
import handleFetchError from '../resource/handleFetchError.js';
import {
  SET_NETWORKING_FETCHING,
  SET_NETWORKING_SUCCESS,
  SET_NETWORKING_ERROR,
} from '../ActionTypes.js';

/**
 * Fetch access token
 * @kind action
 * @param {string} {refreshToken} - refresh token
 * @param {string} {turnstileToken} - turnstile token
 * @param {string} {via} - where does the fetch triggered
 * @param {string} {triggerToken} - the old token which triggered refresh
 * @return {Promise} Action promise.
 */
const fetchAccessToken =
  ({ refreshToken, turnstileToken, via, triggerToken } = {}) =>
  async (dispatch, getState) => {
    if (triggerToken) {
      const oldToken = getMeData(getState(), 'token');
      if (oldToken && oldToken !== triggerToken) {
        // maybe triggered by wrong token, ex: stream token
        // TODO: should also check if oldToken is expired
        return dispatch({ type: '' });
      }
    }

    const currentRefreshToken = getMeData(getState(), 'refreshToken');
    if (!refreshToken && !currentRefreshToken) {
      return dispatch({ type: '' });
    }

    const selectPath = ['auth', 'tokens'];
    const fetchOptions = {
      method: 'POST',
      headers: {
        ...getHeaders(),
        Authorization: `Bearer ${refreshToken || currentRefreshToken}`,
      },
    };

    if (turnstileToken) {
      fetchOptions.headers['X-Turnstile-Token'] = turnstileToken;
    }

    const url = getResourceUrl({ endpoint: '/auth/tokens' });

    dispatch({ type: SET_NETWORKING_FETCHING, payload: { selectPath } });
    try {
      let response = await fetch(url.href, fetchOptions);

      if (!response.ok) {
        await handleFetchError({ response });
      }

      const {
        access_token: accessToken,
        refresh_token: refreshTokenFromResponse,
      } = await response.json();

      if (refreshTokenFromResponse) {
        // if request with v1 token
        // response will get pair of refresh and access token to integrate v2 auth flow
        dispatch(
          mergeMeData({
            field: 'refreshToken',
            value: refreshTokenFromResponse,
          })
        );
      } else if (!currentRefreshToken) {
        // Login Will not save refresh token till get first access token
        dispatch(mergeMeData({ field: 'refreshToken', value: refreshToken }));
      }

      if (accessToken) {
        dispatch(mergeMeData({ field: 'token', value: accessToken }));
      }

      return dispatch({
        type: SET_NETWORKING_SUCCESS,
        payload: { selectPath },
      });
    } catch (error) {
      // When fetchAccessToken is dispatched from jwtRefresh and fails,
      // we don't have to logout and still could use the the current access token
      if (via === 'jwtRefresh' || via === 'customFetch-parallel') {
        return dispatch({ type: '' });
      }

      return dispatch({
        type: SET_NETWORKING_ERROR,
        payload: { selectPath, error },
      });
    }
  };

export default fetchAccessToken;
