// messageConstants.js
'use strict';

export const MessageMoreFeature = {
  REPORT: 'report',
  BLOCK: 'block',
  FOLLOW: 'follow',
  SHARE: 'share',
  COPY: 'copy',
  PROFILE: 'profile',
  DELETE: 'delete',
  CLIP: 'clip',
  PIN: 'pin',
  HIDE: 'hide',
};

export const MessageCtaType = {
  PRODUCT: 'product',
  POST: 'post',
  LIVESTREAM: 'livestream',
};
