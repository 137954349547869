// customFetch.js
'use strict';
import 'isomorphic-fetch';

import { customFetch as customFetchLog } from '../resource/debug.js';
import { TOKEN_EXPIRED_THRESHOLD } from '../RemoteConfigKeys.js';

const log = customFetchLog.extend('log');
const errorLog = customFetchLog.extend('error');

let tokenRenewer = null;

/**
 * Set token renewer to customFetch
 * @param {function} {tokenRenewer} - function to pre-check and update header access token
 * @param {bool} {[shouldReplace = false]} - wheather should replace old renewer
 */
export const setTokenRenewer = ({
  tokenRenewer: inputTokenRenewer,
  shouldReplace = false,
}) => {
  if (shouldReplace || !tokenRenewer) {
    tokenRenewer = inputTokenRenewer;
    log('setTokenRenewer', { inputTokenRenewer, shouldReplace });
  }
};

/**
 * Create token renewer function to customFetch
 * @param {getState} {getState} - redux store getState
 * @param {dispatch} {dispatch} - redux store dispatch
 */
export const createTokenRenewer = ({ getState, dispatch }) => {
  log('createTokenRenewer()');
  let expiredThreshold;
  let processTokenPromise = null;

  const processToken = async ({ token }) => {
    log('processToken()', { token, dispatch, getState });
    const state = getState();
    const [
      { default: getOperationData },
      { default: getTimestampOffset, Accuracy },
      { default: getCurrentUnixTimestamp },
    ] = await Promise.all([
      import('../selector/getOperationData.js'),
      import('../selector/getTimestampOffset.js'),
      import('../resource/getCurrentUnixTimestamp.js'),
    ]);
    const loadGetRemoteConfigDataPromise = import(
      '../selector/getRemoteConfigData.js'
    );
    const loadDecodePromise = import('jwt-decode');

    const serverTimestamp = getOperationData(state, ['timestamp'], 'server');
    if (!serverTimestamp) {
      log('processToken() fetch time');
      const fetchTime = (await import('../action/fetchTime.js')).default;
      await dispatch(fetchTime());
    }

    const timestampOffsetSeconds = getTimestampOffset(
      getState(),
      Accuracy.SECOND
    );
    const now = getCurrentUnixTimestamp({
      offsetSeconds: timestampOffsetSeconds,
    });
    log('processToken()', { now });

    // get the current exp from the access token
    const decode = (await loadDecodePromise).jwtDecode;
    const decoded = decode(token);
    const currentExp = decoded.exp;
    if (!currentExp) {
      // the token won't expire
      return token;
    }

    const remainDuration = (currentExp - now) * 1000;
    log('processToken()', { currentExp, remainDuration });

    if (!expiredThreshold) {
      const getRemoteConfigData = (await loadGetRemoteConfigDataPromise)
        .default;

      expiredThreshold = getRemoteConfigData(state, TOKEN_EXPIRED_THRESHOLD);
    }
    log('processToken()', { expiredThreshold });

    if (remainDuration < expiredThreshold) {
      const fetchAccessToken = (await import('../action/fetchAccessToken.js'))
        .default;
      if (remainDuration > 0) {
        // Token is about to expire, refresh in parallel
        log('processToken() refresh in parallel');
        dispatch(
          fetchAccessToken({
            triggerToken: token,
            via: 'customFetch-parallel',
          })
        );
      } else {
        // Token has expired, need to block fetch and refresh
        log('processToken() block fetch for refresh');
        const loadGetMeDataPromise = import('../selector/getMeData.js');
        const [{ default: getMeData }] = await Promise.all([
          loadGetMeDataPromise,
          dispatch(
            fetchAccessToken({
              triggerToken: token,
              via: 'customFetch-block',
            })
          ),
        ]);
        const newToken = getMeData(getState(), 'token');
        log('processToken()', { newToken });
        return newToken;
      }
    }

    return token;
  };

  return async ({ url, options }) => {
    log('tokenRenewer()', { url, options, dispatch, getState });
    const result = { url, options };

    try {
      const token = options.headers
        .get('Authorization')
        .match(/^Bearer (\S+)$/)?.[1];
      log('tokenRenewer()', { token });

      if (token) {
        let newToken = token;
        if (!processTokenPromise) {
          log('tokenRenewer() new process token promise');
          processTokenPromise = processToken({ token });
          newToken = await processTokenPromise;
          processTokenPromise = null;
        } else {
          log('tokenRenewer() reuse process token promise');
          newToken = await processTokenPromise;
        }
        log('tokenRenewer()', { token, newToken });

        if (token !== newToken) {
          options.headers.set('Authorization', `Bearer ${newToken}`);
          log('tokenRenewer() option replaced');
        }
      }
    } catch (error) {
      errorLog('tokenRenewer()', { error });
    }

    return result;
  };
};

/**
 * Custom fetch
 * @param {string} url - regular url param for native fetch.
 * @param {object} options - regular options param for native fetch.
 */
const customFetch = async (inputUrl, inputOptions) => {
  let url = inputUrl;
  let options = inputOptions;

  if (options?.headers) {
    // input may be object style or Headers object
    const headers = new Headers(options?.headers);
    options.headers = headers;
  }
  const authorizationHeader = options?.headers?.get('Authorization');

  log('init', {
    inputUrl,
    inputOptions,
    options,
    tokenRenewer,
    authorizationHeader,
  });

  if (tokenRenewer && authorizationHeader) {
    const { url: newUrl, options: newOptions } = await tokenRenewer({
      url,
      options,
    });
    url = newUrl;
    options = newOptions;

    log('processed', { url, options });
  }

  const result = await fetch(url, options);
  return result;
};

export default customFetch;
