// getTimestampOffset.js
'use strict';
import createCachedSelector from '../resource/createCachedSelector.js';

import getOperationData from '../selector/getOperationData.js';

export const Accuracy = {
  MILLISECOND: 'ms',
  SECOND: 's',
};

/**
 * Select timestamp offset between server and client.
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {string} accuracy - time accuracy.
 * @return {number|undefined} timestamp offset between server and client.
 */
const getTimestampOffset = createCachedSelector(
  state => getOperationData(state, ['timestamp'], 'client'),
  state => getOperationData(state, ['timestamp'], 'server'),
  (state, accuracy = Accuracy.SECOND) => accuracy,
  (clientTimestamp = 0, serverTimestamp = 0, accuracy) => {
    if (!Object.values(Accuracy).includes(accuracy)) {
      return;
    }
    const offset = serverTimestamp - clientTimestamp;
    return Accuracy.MILLISECOND === accuracy
      ? offset
      : Math.floor(offset / 1000);
  }
)((state, accuracy = Accuracy.SECOND) => `${accuracy}`);

export default getTimestampOffset;
