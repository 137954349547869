// publicUrlPathConstants.js
import {
  PUBLIC_APP_URL_PATH,
  PUBLIC_BADGE_URL_PATH,
  PUBLIC_LEADERBOARD_URL_PATH,
  PUBLIC_LOGO_URL_PATH,
  PUBLIC_MESSAGE_URL_PATH,
  PUBLIC_PRODUCT_URL_PATH,
  PUBLIC_SHOP_URL_PATH,
  PUBLIC_USER_URL_PATH,
} from '../RemoteConfigKeys';

export const MESSAGE = PUBLIC_MESSAGE_URL_PATH;
export const PRODUCT = PUBLIC_PRODUCT_URL_PATH;
export const BADGE = PUBLIC_BADGE_URL_PATH;
export const USER = PUBLIC_USER_URL_PATH;
export const LEADERBOARD = PUBLIC_LEADERBOARD_URL_PATH;
export const SHOP = PUBLIC_SHOP_URL_PATH;
export const LOGO = PUBLIC_LOGO_URL_PATH;
export const APP = PUBLIC_APP_URL_PATH;

export const defaultPublicUrlPathMap = {
  [MESSAGE]: process.env.PUBLIC_MESSAGE_URL_PATH,
  [PRODUCT]: process.env.PUBLIC_PRODUCT_URL_PATH,
  [BADGE]: process.env.PUBLIC_BADGE_URL_PATH,
  [USER]: process.env.PUBLIC_USER_URL_PATH,
  [LEADERBOARD]: process.env.PUBLIC_LEADERBOARD_URL_PATH,
  [SHOP]: process.env.PUBLIC_SHOP_URL_PATH,
  [LOGO]: process.env.PUBLIC_LOGO_URL_PATH,
  [APP]: process.env.PUBLIC_APP_URL_PATH,
};

export const publicUrlPathConstantsArray = [
  MESSAGE,
  PRODUCT,
  BADGE,
  USER,
  LEADERBOARD,
  SHOP,
  LOGO,
  APP,
];
