// createI18nInstance.js
import i18n from '../resource/i18n.js';
import retryWithBackoff from '../resource/retryWithBackoff.js';

let globalI18nInstance;

const isServer = typeof window === 'undefined';

const createI18nInstanceForNode = config => {
  let instance;

  if (!globalI18nInstance) {
    globalI18nInstance = i18n;
  }
  // Use globalI18nInstance.cloneInstance(config) for reuse the i18n instance needs to init again
  // and fetch the translation might increase the rendering time
  instance = globalI18nInstance;

  let initI18nPromise;

  if (!instance.isInitialized) {
    initI18nPromise = retryWithBackoff({
      promiseFunction: () =>
        new Promise((resolve, reject) => {
          instance.init(config, error => {
            // Reject only when all languages are failed to fetch
            if (error?.length === i18n.languages?.length) reject(error);
            else resolve();
          });
        }),
      getNextTimeToWait: () => 5000,
      maxRetries: 5,
    });
  } else {
    initI18nPromise = Promise.resolve(i18n.t);
  }

  return { i18n: instance, initI18nPromise };
};

const createI18nInstanceForBrowser = config => {
  let instance = i18n;

  if (!instance.isInitialized) {
    instance.init(config);
  }

  return { i18n: instance };
};

const createI18nInstance = isServer
  ? createI18nInstanceForNode
  : createI18nInstanceForBrowser;

export default createI18nInstance;
