// processUploadJob.js
'use strict';
import getListData from '../selector/getListData.js';
import getOperationData from '../selector/getOperationData.js';
import getAssetAverageProgress from '../selector/getAssetAverageProgress.js';

import { Status as UploadStatus } from '../resource/uploadConstants.js';
import { upload as uploadDebug } from '../resource/debug.js';

const uploadLog = uploadDebug.extend('log:action/processUploadJob.js');

/**
 * Process upload queue
 * @kind action
 * @return {Promise} Action promise.
 */
const processUploadJob = () => async (dispatch, getState) => {
  uploadLog('execute processUploadJob', {
    uploadJobIds: getListData(getState(), ['uploadJob'], 'itemIds'),
  });
  const uploadJobIds = getListData(getState(), ['uploadJob'], 'itemIds') || [];
  if (uploadJobIds.length === 0) {
    uploadLog('finish processUploadJob without upload job');
    return dispatch({ type: '' });
  }
  const uploadingJobId = uploadJobIds.find(uploadJobId => {
    const status = getOperationData(
      getState(),
      ['uploadJob', uploadJobId],
      'status'
    );
    const assetIds = getOperationData(
      getState(),
      ['uploadJob', uploadJobId],
      'assetIds'
    );
    const progress = getAssetAverageProgress(getState(), assetIds);
    return UploadStatus.UPLOAD_STARTED === status && progress < 100;
  });
  if (uploadingJobId) {
    uploadLog('finish processUploadJob with uploading job');
    return dispatch({ type: '' });
  }
  const pendingJobId = uploadJobIds
    .filter(uploadJobId => {
      const status = getOperationData(
        getState(),
        ['uploadJob', uploadJobId],
        'status'
      );
      return UploadStatus.PENDING === status;
    })
    .sort((aUploadJobId, bUploadJobId) => {
      const aPriority = getOperationData(
        getState(),
        ['uploadJob', aUploadJobId],
        'priority'
      );
      const bPriority = getOperationData(
        getState(),
        ['uploadJob', bUploadJobId],
        'priority'
      );
      return bPriority - aPriority;
    })[0];
  if (!pendingJobId) {
    uploadLog('finish processUploadJob without pending job');
    return dispatch({ type: '' });
  }
  const calculateAssetMd5AndUploadAsset = (
    await import('../action/calculateAssetMd5AndUploadAsset.js')
  ).default;
  uploadLog('finish processUploadJob');
  return dispatch(
    calculateAssetMd5AndUploadAsset({ uploadJobId: pendingJobId })
  );
};

export default processUploadJob;
